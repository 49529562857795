/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import { fade, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import A_AppBar from "../../../atoms/surfaces/appbars/A_AppBar";
import ClientLogo from "../../../../../Assets/Images/Defaults/minerva-logo.svg";
import M_UserArea from "./M_UserArea";
import M_AddEntity from "./AddEntity/M_AddEntity";
import { useMediaQuery } from "@material-ui/core";

import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";

import {
  excludedClientsForAddEntity,
  isClientAuthorised,
} from "./M_NavBarhelper";

const useStyles = makeStyles((theme) => ({
  wrapperDiv: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  wrapperDivToggle: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "97vw",
      position: "fixed",
      zIndex: "89",
      paddingTop: "1.2em",
      paddingBottom: "1.2em",
      marginLeft: "-60vw",
      backgroundColor: "#5e5959",
      borderRadius: "5px",
      height: 43,
      display: "flex",
      alignItems: "center",
    },
  },

  closeIconOnMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginLeft: "87vw !important",
      "&:focus": {
        fontSize: "32px",
      },
      "&:hover": {
        transform: "rotate(180deg)",
      },
    },
  },

  root: {
    flexGrow: 1,
  },
  ToolbarColor: {
    color: "#222",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginTop: "-0.2em",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  menuButton: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
      width: "12ch",
      backgroundColor: "#60606052",

      "&:focus": {},
    },
    marginLeft: 0,
    marginRight: 10,
    width: "30%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-5px",
    },
  },
  inputRoot: {
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  inputRootToggle: {
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-3px",
      fontSize: "13px",
      width: "5.5ch",
      "&:focus": {
        width: "100ch",
      },
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  hideSearch: {
    display: "none",
  },

  person: {
    marginRight: "11%",
    width: "0px",
    height: "0px",
    marginTop: "-3%",
  },
}));
const BeforeLogin = (props) => {
  const classes = { props };
  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>
        Minerva Cloud
      </Typography>
    </React.Fragment>
  );
};

const AfterLogin = (props) => {
  let history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");
  const currentURL = window.location.href;
  const lastWord = currentURL.split("/").pop();
  const classes = useStyles();
  const isSeachShow = localStorage.getItem("showSeach");

  const showEntity = isClientAuthorised(
    window.location.hostname,
    excludedClientsForAddEntity
  );
  const [defValue, setDefValue] = useState("");
  const [InitialBooleanValue, setInitialBooleanValue] = useState(true);
  const toggleBooleanValue = () => {
    setInitialBooleanValue(false);
  };
  const toggleBooleanValueAgain = () => {
    setInitialBooleanValue(true);
  };

  React.useLayoutEffect(() => {
    setDefValue("");
  }, [props && props.currentPage && props.currentPage.data]);

  const LogoRedirection = () => {
    if (!props.currentPage.isLoading) {
      history.push(localStorage.UserDefaultURL);
    }
  };

  return (
    <Toolbar
      style={
        isMobile
          ? {
              backgroundColor: "#222",
              objectFit: "contain",
              overflow: "hidden",
              display: "flex",
              justifyContent: "space-between",
              width: "109vw",
            }
          : {
              backgroundColor: "#222",
              objectFit: "contain",
              overflow: "hidden",
              display: "flex",
              justifyContent: "space-between",
              width: "100vw",
            }
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <A_IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          size="medium"
          icon={<MenuIcon />}
          className={classes.menuButton}
          onClick={(e) => props.handleDrawer(true)}
        ></A_IconButton>
        <Typography variant="h6" className={classes.title}>
          <artical className="mobile">
            <img
              style={
                props.currentPage.isLoading
                  ? { cursor: "" }
                  : { cursor: "pointer" }
              }
              src={ClientLogo}
              edge="start"
              width={100}
              onClick={(e) => LogoRedirection(e)}
            />
          </artical>
        </Typography>{" "}
        <artical className="tablet">
          <img
            src={ClientLogo}
            edge="start"
            width={isMobile ? 75 : 100}
            className="imgtablet"
            onClick={(e) => LogoRedirection(e)}
          />
        </artical>
      </div>
      {lastWord !== "securelogout" ? (
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "3%" }}
        >
          <div>
            <div
              style={
                isMobile
                  ? { borderRadius: "50px", marginLeft: "10%" }
                  : { borderRadius: "50px" }
              }
              className={
                isSeachShow === "true" ? classes.search : classes.hideSearch
              }
            >
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
               <InputBase
                  value={defValue}
                  id="globalsearch"
                  placeholder="Search.."
                  onChange={(e) => {
                    setDefValue(e.target.value), props.onChange(e);
                  }}
                  classes={{
                    root: InitialBooleanValue
                      ? classes.inputRoot
                      : classes.inputRootToggle,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
            </div>
          </div>
          <div className={showEntity ? "" : classes.hideSearch}>
            <M_AddEntity edge="end" {...props} />
          </div>
          <div>
            <M_UserArea edge="end" {...props} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

const M_NavBar = (props) => {
  const { user } = props;
  return (
    <div>
      <A_AppBar
        style={{ color: "#222" }}
        position="fixed"
        content={
          user === null ? (
            <BeforeLogin {...props} />
          ) : (
            <AfterLogin
              {...props}
              onChange={props.onChange}
              getCurrentPage={props}
            />
          )
        }
      />
    </div>
  );
};
M_NavBar.defaultProps = {
  user: null,
};

M_NavBar.propTypes = {
  onChange: PropTypes.any,
  user: PropTypes.any,
  currentPage: PropTypes.any,
};

AfterLogin.propTypes = {
  handleDrawer: PropTypes.any,
  onChange: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_NavBar);

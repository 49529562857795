import { handleOptionSetValue } from "../../../../../../../Utils/Helpers/optionSet.helper";
import * as httpCall from "../../../../../../../Utils/apis/apis";
const getTemplate = async () => {
  let res = await httpCall.httpGet("/CRMData/getSearchTemplate");
  return res;
};
const getSearchData = async (keyword) => {
  let res = await httpCall.httpGet(
    `/CRMData/perspicaciousSearch?keyword=${keyword}`
  );
  return res;
};
const filterTemplateValue = (templateConditions, field, value) => {
  let condition = templateConditions.filter(
    (condition) => condition.property === field
  );
  if (condition.length > 0) {
    let defaultCondition = condition[0];
    let defaultValue = "";
    defaultCondition.values.forEach(
      (item) =>
        (defaultValue =
          item.value === value
            ? item.default
            : defaultCondition &&
              defaultCondition.default 
            ? ""
            : value)
    );
    return defaultValue;
  }
};
export const getNewSearchResult = async (value) => {
  let template = {};
  if (localStorage.getItem("searchTemplateNewData")) {
    try {
      template = JSON.parse(localStorage.getItem("searchTemplateNewData"));
    } catch (e) {
      let tempTemplate = await getTemplate();
      template = tempTemplate.searchResultTemplate;
      localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
    }
  } else {
    let tempTemplate = await getTemplate();
    template = tempTemplate.searchResultTemplate;
    localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
  }
  let searchResult = await getSearchData(value);
  let finalSearchResult = [];
  for (let searchData in searchResult) {
    let searchSelectedTemplates = template.filter((item) => {
      return (
        item.label.toLowerCase() ===
        searchResult[searchData].label.toLowerCase()
      );
    });
    if (searchSelectedTemplates.length == 0) {
      continue;
    }
    searchSelectedTemplates = searchSelectedTemplates[0];
    let conditions =
      (searchSelectedTemplates.conditionalMapping &&
        searchSelectedTemplates.conditionalMapping.template) ||
      [];
    let fieldForChips = [...new Set(conditions.map((item) => item.property))];
    let fieldForSubtitle = searchSelectedTemplates.subtitle.filter(
      (item) => !fieldForChips.includes(item)
    );
    let chipData = fieldForChips.map((field) =>
      filterTemplateValue(
        conditions,
        field,
        handleOptionSetValue(searchResult[searchData][field])
      )
    );

    let title = searchSelectedTemplates.title
      .map((titleName) => {
        return searchResult[searchData][titleName];
      })
      .join(" ");

    let subtitle = fieldForSubtitle.map((titleName) => {
      return handleOptionSetValue(searchResult[searchData][titleName] || "");
    });
    subtitle = subtitle
      .filter((item) => (typeof item === "string" ? item.trim() !== "" : true))
      .join("\n");
    let id = searchResult[searchData]["id"];
    let header = searchSelectedTemplates["header"]
      ? searchSelectedTemplates["header"]
      : "";
    let pageUrl = searchSelectedTemplates["label"]
      ? searchSelectedTemplates["label"]
      : "";
    let linkValue = searchResult[searchData]["link"];
    let pageId = searchSelectedTemplates["pageId"]
      ? searchSelectedTemplates["pageId"]
      : "";
    let resultTemplate = {
      title: title,
      email: subtitle,
      header: header,
      id: id,
      pageId: pageId,
      pageUrl: pageUrl,
      link: linkValue,
      chips: chipData,
    };
    finalSearchResult.push(resultTemplate);
  }
  return finalSearchResult;
};

/* eslint-disable */
import { loadState } from "../LocalState/localState";
import { getFormattedDate } from "./dateUtils";
import React from "react";
import { dataTypes } from "./dataTypes";
import { Operator } from "./Operator";
import BusinessIcon from "../../../src/Assets/Images/Defaults/company.png";
import PersonPinIcon from "../../../src/Assets/Images/Defaults/contact.png";
import PeopleIcon from "../../../src/Assets/Images/Defaults/deal.png";
import MonetizationOnIcon from "../../../src/Assets/Images/Defaults/Investment.png";
import AccountBalanceIcon from "../../../src/Assets/Images/Defaults/Loan.png";
import LocationOnIcon from "../../../src/Assets/Images/Defaults/map.png";
import EventNoteIcon from "../../../src/Assets/Images/Defaults/Meeting.png";
import InsertChartIcon from "../../../src/Assets/Images/Defaults/Portfolio.png";
import Default_Avatar from "../../../src/Assets/Images/Defaults/defLogo.png";
import { handleOptionSetValue } from "./optionSet.helper";

/* List of supported browser with minimum version supported. */
export const supportedBrowsersList = [
  {
    name: "Chrome",
    version: "63.0",
    upgradeLink: "https://www.google.com/intl/en_in/chrome/",
  },
  {
    name: "Edge",
    version: "42",
    upgradeLink: "https://www.microsoft.com/en-us/edge",
  },
  {
    name: "Safari",
    version: "10.12",
    upgradeLink: "https://support.apple.com/downloads/safari",
  },
];
export const renderImage = (image) => {
  let val;
  switch (image) {
    case "InsertChartIcon":
      val = InsertChartIcon;
      break;
    case "EventNoteIcon":
      val = EventNoteIcon;
      break;
    case "LocationOnIcon":
      val = LocationOnIcon;
      break;
    case "AccountBalanceIcon":
      val = AccountBalanceIcon;
      break;
    case "PersonPinIcon":
      val = PersonPinIcon;
      break;
    case "MonetizationOnIcon":
      val = MonetizationOnIcon;
      break;
    case "PeopleIcon":
      val = PeopleIcon;
      break;
    case "BusinessIcon":
      val = BusinessIcon;
      break;
    default:
      val = Default_Avatar;
      break;
  }
  return val;
};

/*CRM Contact Info LocalStorage*/
export const getCRMContactInfo = () => {
  let crmContactUserId = "";
  let crmContactUserName = "";
  let crmContactUserEmail = "";
  let crmContactUserPreference = "";
  try {
    let crmContactUserInfo = JSON.parse(localStorage.getItem("contact_crm"));
    crmContactUserId =
      crmContactUserInfo && crmContactUserInfo.id ? crmContactUserInfo.id : "";
    crmContactUserName =
      (crmContactUserInfo.properties.firstName
        ? crmContactUserInfo.properties.firstName
        : "") +
      " " +
      (crmContactUserInfo.properties.lastName
        ? crmContactUserInfo.properties.lastName
        : "");
    crmContactUserEmail = crmContactUserInfo.properties.email
      ? crmContactUserInfo.properties.email
      : "";
    crmContactUserPreference = crmContactUserInfo.properties
      .IsUserPreferenceActivated
      ? crmContactUserInfo.properties.IsUserPreferenceActivated
      : "";
  } catch (e) {
    //intentionally write comment
  }
  return {
    crmContactUserId,
    crmContactUserName,
    crmContactUserEmail,
    crmContactUserPreference,
  };
};

/* User related functions */
export const getCurrentUser = (isAll) => {
  let user = null;
  const appState = loadState();
  if (appState) {
    user = appState.oidc.user;
  }
  try {
    if (user === null) {
      return {};
    }
    if (isAll) {
      return user;
    }
    let userInitials = `${user.profile.given_name.substring(
      0,
      1
    )}${user.profile.family_name.substring(0, 1)}`;
    let displayName = `${user.profile.given_name} ${user.profile.family_name}`;
    let userDesignation =
      user.profile && user.profile.designation ? user.profile.designation : "";

    return { userInitials, displayName, userDesignation };
  } catch (e) {
    console.log("getCurrentUser failed: ", e);
  }
};

/* ends here User related functions */

export const getClientBrowser = () => {
  let browserInfo = {
    name: "",
    version: "",
  };
  let { userAgent } = navigator;
  if (userAgent.indexOf("Edge") > -1) {
    browserInfo.name = "Microsoft Edge";
    browserInfo.version = userAgent
      .split("Edge/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Firefox") > -1) {
    browserInfo.name = "Firefox";
    browserInfo.version = userAgent
      .split("Firefox/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    browserInfo.name = "Safari";
    browserInfo.version = userAgent
      .split("Safari/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Chrome") > -1) {
    browserInfo.name = "Chrome";
    browserInfo.version = userAgent
      .split("Chrome/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  return browserInfo;
};

//This functions checks for supported browsers.
export const isUnsupportedBrowser = () => {
  let isUnsupported = false;
  const clientBrowser = getClientBrowser();
  let matchedBrowser = supportedBrowsersList.filter((browser) => {
    return browser.name === clientBrowser.name;
  });

  if (matchedBrowser.length === 0) {
    isUnsupported = true;
  } else if (
    matchedBrowser[0].name === clientBrowser.name &&
    parseFloat(matchedBrowser[0].version) > parseFloat(clientBrowser.version)
  ) {
    isUnsupported = true;
  }
  return isUnsupported;
};

//This function returns http config to sent in http calls.
export const getHttpConfig = (config = {}) => {
  let currentUser = getCurrentUser(true);
  let access_token = null;
  if (currentUser !== null) {
    access_token = currentUser.access_token;
  }
  let auth_Head = {
    Authorization: "Bearer " + access_token,
  };
  return {
    headers: Object.assign(config, auth_Head),
  };
};

export const getEvaluatedValue = (expression, data) => {
  try {
    let expArry = expression.slice(1, 1000).split("d.");
    let resolvedExp = [];
    for (let i = 0; i < expArry.length; i++) {
      let fieldKeyArry = expArry[i].split("+");

      for (let j = 0; j < fieldKeyArry.length; j++) {
        let fieldKeyRaw = fieldKeyArry[j];
        let fieldKeyTrimmed = fieldKeyArry[j].trim();
        if (fieldKeyTrimmed) {
          if (fieldKeyTrimmed in data) {
            let fieldValue = data[fieldKeyTrimmed];
            resolvedExp.push(fieldValue);
          } else {
            resolvedExp.push("");
          }
        }
      }
    }
    let finalStringTrimmed = resolvedExp.join("");
    let finalString = finalStringTrimmed
      .replace(/(['])/g, " ")
      .replace("  ", "");
    console.log("resolved Exp:", finalString);
    return finalString;
  } catch (e) {
    console.log("getEvaluatedValue error: ", e);
    return undefined;
  }
};

export const showField = (expressionFirst, dataFirst) => {
  if (dataFirst) {
    let operators = {
      eq: Operator.equal,
      ne: Operator.notEqual,
      lt: Operator.lessThan,
      gt: Operator.greaterThan,
      le: Operator.lessThanEqual,
      ge: Operator.greaterThanEqual,
      hasValue: Operator.hasValue,
    };
    let globalComparators = {
      now: new Date(),
      currentUser: "Minerva Cloud",
      env: "dev",
    };
    let logicalOprators = { AND: "&&", OR: "||" };
    let finalExpReadable = "(";
    let finalExpression = "(";
    function evalShow(expression, data) {
      let opr = expression.type;
      let conditions =
        expression && expression.conditions ? expression.conditions : null;

      for (let i = 0; i < conditions.length; i++) {
        let { type, field, op, value, filterValue } = conditions[i];
        if (!type) {
          let compareOpr = operators[op];
          let compareWithValue = value;
          if (
            typeof compareWithValue === dataTypes.STRING &&
            compareWithValue.indexOf("~") > -1
          ) {
            compareWithValue =
              globalComparators[compareWithValue.slice(1, 1000)];
          }
          if (!compareOpr) {
          } else {
            if (compareOpr === Operator.hasValue) {
              if (!data[field]) {
                finalExpression = `${finalExpression} 1===2)`;
                return false;
              } else {
                // just to return true, when hasVlaue is satisfied
                compareOpr = Operator.equal;
                compareWithValue = handleOptionSetValue(data[field], false);
              }
            }
            finalExpReadable =
              finalExpReadable = `${finalExpReadable} ${field} ${compareOpr} ${compareWithValue} ${
                i === conditions.length - 1 ? "" : logicalOprators[opr]
              }`;

            let fieldValue = filterValue
              ? data["filter"] && data["filter"][field]
                ? data["filter"][field]
                : ""
              : data[field] === undefined
              ? ""
              : handleOptionSetValue(data[field], false);
            if (
              typeof compareWithValue === "string" &&
              compareWithValue.charAt(0) === "="
            ) {
              compareWithValue = handleDynamicValue(
                data[compareWithValue.slice(1, 1000)],
                fieldValue
              );
            }
            finalExpression = `${finalExpression} "${fieldValue}" ${compareOpr} "${compareWithValue}" ${
              i === conditions.length - 1 ? "" : logicalOprators[opr]
            }`;
          }
        } else {
          finalExpReadable = `${finalExpReadable} (`;
          finalExpression = `${finalExpression} (`;
          evalShow(conditions[i], data);
        }
        if (i == conditions.length - 1) {
          finalExpReadable = `${finalExpReadable} )`;
          finalExpression = `${finalExpression} )`;
        }
      }
    }
    if (expressionFirst === true || expressionFirst === false) {
      return true;
    }
    evalShow(expressionFirst, dataFirst);
    try {
      return eval(finalExpression);
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const getTheme = () => {
  const minervaTheme = localStorage.getItem("minervaTheme");
  if (minervaTheme === "null") {
    return minervaTheme;
  }
  return JSON.parse(minervaTheme);
};

//This function returns a valid source for dropdowns i.e. in label, value format
export const convertToLabelValueFormat = (
  id,
  name,
  options,
  addEmptyOption
) => {
  let convertedOptions = addEmptyOption ? [{ value: "", label: "Select" }] : [];
  for (let i = 0; i < options.length; i++) {
    let item = options[i];
    convertedOptions.push({ ...item, value: item[id], label: item[name] });
  }
  return convertedOptions;
};
//get probability field on basis of status
export const status_Probability = {
  Closed: "100",
  Dead: "0",
  Priority: "90",
  Pipeline: "60",
  Developing: "30",
  Tracking: "5",
};

export const handelDateArray = (data, format) => {
  let convertData = "";
  try {
    convertData = JSON.parse(data);
    if (convertData.timeZone) {
      let newDate = new Date(convertData.dateTime + "Z");
      let year = newDate.getFullYear();
      let Month = newDate.getMonth() + 1;
      let day = newDate.getDate();
      let CompleteDate = day + "/" + Month + "/" + year;
      let res = getFormattedDate(CompleteDate, format);
      if (res == "") {
        return CompleteDate;
      }
      return res;
    }
  } catch (e) {
    return getFormattedDate(data, format);
  }
};

export const handelArrayFormat = (data) => {
  let convertData = "";
  try {
    convertData = JSON.parse(data);
    if (convertData.length > 0) {
      var returnData = convertData[0].emailAddress.address;
    } else {
      var returnData = convertData.emailAddress.address;
    }
    return returnData;
  } catch (e) {
    return data;
  }
};

//TODO - Refactor to new js file
export const schedulerFrequency = {
  Daily: "1",
  Weekly: "2",
  Monthly: "3",
  Quaterly: "4",
  "Semi-Annually": "5",
  Annually: "6",
};
//TODO - Refactor to config file
export const schedulerRemainderTemplate = {
  RCapitalPortfolio: "495",
};

export const getUrlFromString = (initialValue, dataSet, pageUrl, crmId) => {
  let finalString = "";
  // TODO - create config entries for all id and label
  let initialURLSet = initialValue.split("/");
  for (let valueIndex in initialURLSet) {
    switch (initialURLSet[valueIndex].toLowerCase()) {
      case "=pageurl":
        initialURLSet[valueIndex] = pageUrl ? pageUrl : "";
        break;
      case "=crmid":
        initialURLSet[valueIndex] = crmId ? crmId : "";
        break;
      case "=recordid":
        initialURLSet[valueIndex] = dataSet && dataSet.id ? dataSet.id : "";
        break;
      case "=label":
        initialURLSet[valueIndex] =
          dataSet && dataSet.label ? dataSet.label : "";
        break;
      default:
        break;
    }
  }
  finalString = initialURLSet.join("/");
  return finalString;
};
const handleDynamicValue = (value, fieldValue) => {
  try {
    let parsedValue = JSON.parse(value);
    if (Array.isArray(parsedValue)) {
      for (let valueIndex in parsedValue) {
        let tempValue = parsedValue[valueIndex];
        if (tempValue["title"]) {
          if (tempValue["id"] == fieldValue) {
            return tempValue["id"];
          }
        } else if (tempValue["label"]) {
          if (tempValue["value"] && tempValue["value"] == fieldValue) {
            return tempValue["value"];
          } else if (tempValue["label"]) {
            return tempValue["label"];
          }
        }
      }
    } else if (typeof parsedValue === "object") {
      if (parsedValue["title"]) {
        if (parsedValue["id"] == fieldValue) {
          return parsedValue["id"];
        }
      } else if (parsedValue["label"]) {
        if (parsedValue["value"] && parsedValue["value"] == fieldValue) {
          return parsedValue["value"];
        } else if (parsedValue["label"]) {
          return parsedValue["label"];
        }
      }
    }
    return value;
  } catch (error) {
    return value;
  }
};

import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash/debounce";
import theme from "../../../../Themes/defaultTheme";
import * as httpCall from "../../../../../Utils/apis/apis";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import M_LookupInfo from "../../../Molecules/Common/Renderers/Helper/M_LookupInfo";
import { message } from "../../../../../Utils/config/messages";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: 5,
  },
  selectLabel: {
    marginTop: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  helpertestIcon:{
    "& div":{
      paddingRight:"0px!important"
    }
  }
}));
const A_Autocomplete = (props) => {
  const classes = useStyles();
  const {
    className,
    createNew,
    debounceDelay,
    defaultValue,
    destinationVertex,
    disableClearable,
    disabled,
    edgeLabel,
    error,
    freeSolo,
    helperText,
    id,
    label,
    margin,
    onChange,
    required,
    resultTemplate,
    size,
    variant,
    filters,
    fullWidth,
    multiple,
    loogedInUserValue,
    tabular,
    edgeFilter,
  } = props;
  const { crmId } = useParams();
  let defaultValueProps = [];
  if (defaultValue) {
    if (defaultValue.data === "" || defaultValue.data) {
      defaultValueProps = defaultValue.data;
    } else {
      try {
        defaultValueProps = JSON.parse(defaultValue);
      } catch (e) {
        defaultValueProps = defaultValue;
      }
    }
  }
  if (loogedInUserValue) {
    defaultValueProps =
      defaultValueProps[0] === undefined ||
      defaultValueProps[0] === "" ||
      multiple
        ? defaultValueProps
        : defaultValueProps[0];
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");

  const handleValue = (value) => {
    onChange({ data: value ? value : "", edgeLabel: edgeLabel }, id, tabular);
  };
  const debouncedSave = useCallback(
    debounce((nextValue) => getSearchResults(nextValue), debounceDelay),
    [] // will be created only once initially
  );
  const getSearchResults = async (newInputValue) => {
    if (edgeFilter && edgeFilter["id"] && edgeFilter["id"] == "=crmId") {
      edgeFilter["id"] = crmId;
    }
    setIsLoading(true);
    let requestPayload = {
      templateId: resultTemplate,
      label: destinationVertex,
      keyword: newInputValue,
      filters: filters ? filters : undefined,
      edgeFilter: edgeFilter ? edgeFilter : undefined,
    };

    const response = await httpCall.httpPost(
      //`/${tenantId}/CRMData/-API-`,
      "/CRMData/getCRMLookupData",
      requestPayload
    );
    setTitle(
      JSON.parse(
        response && response.template && response.template.templateJson
          ? response.template.templateJson
          : ""
      )
    );
    if ("message" in response) {
      setIsError(response);
      setIsLoading(false);
    } else {
      const responseData = response.data;
      responseData.sort(function (a, b) {
        return a.properties.title.localeCompare(b.properties.title);
      });
      let convertedData = [];
      if (responseData.length > 0) {
        for (let i = 0; i < responseData.length; i++) {
          convertedData.push({
            id: responseData[i].id,
            label: responseData[i].label,
            title: responseData[i].properties.title,
            email: responseData[i].properties.email,
          });
        }
      }
      setOptions(convertedData);
      setIsLoading(false);
      setShow(responseData && responseData.length > 0 ? false : true);
    }
  };
  const callbackSet = async (newInputValue) => {
    setShow(false);
    debouncedSave(newInputValue);
  };
  const handleInputChange = async (e, newInputValue) => {
    setData(newInputValue);
    setShow(false);
    if (newInputValue.length > 1) {
      if (newInputValue && e && e.type === "change") {
        debouncedSave(newInputValue);
      } else {
        setOptions([]);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={error || isError}
        variant={variant}
        className={`${classes.formControl} ${
          fullWidth ? classes.fullWidth : ""
        } ${classes.helpertestIcon}`}
      >
        <Autocomplete
          multiple={multiple}
          disabled={disabled}
          required={required}
          freeSolo={createNew ? true : freeSolo}
          id={id}
          disableClearable={disableClearable}
          options={options}
          getOptionLabel={(option) => (option.title ? option.title : "")}
          defaultValue={
            multiple
              ? defaultValueProps
              : loogedInUserValue
              ? defaultValueProps
              : defaultValueProps[0]
          }
          renderOption={(option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
              {option.image && (
                <img
                  loading="lazy"
                  width="20"
                  src={option.image}
                  srcSet={option.image}
                  alt=""
                />
              )}
              {option.title}
              {option.subtitle && (
                <>
                  <br />
                  <small>{option.subtitle}</small>
                </>
              )}
            </Box>
          )}
          size={size}
          // value={value}
          className={className}
          onChange={(event, newValue) => handleValue(newValue, null, tabular)}
          onInputChange={(event, newInputValue) =>
            handleInputChange(event, newInputValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              margin={margin}
              variant={variant}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {<A_FormHelper fieldHelperText={props.fieldHelperText}/>}
                  </React.Fragment>
                ),
              }}
              required={required}
              error={error}
            />
          )}
        />
        <FormHelperText>
          {isError ? message.CONTACT_ADMIN : helperText}
        </FormHelperText>
      </FormControl>
      {show && createNew && (
        <M_LookupInfo
          {...title}
          lookupCallBack={callbackSet}
          open={false}
          inputData={data}
          label={destinationVertex}
        />
      )}
    </ThemeProvider>
  );
};
A_Autocomplete.defaultProps = {
  label: "Search",
  variant: "outlined",
  color: "primary",
  size: "small",
  required: false,
  disabled: false,
  error: false,
  fullWidth: false,
  margin: "normal",
  helperText: "",
  defaultValue: "",
  focus: false,
  freeSolo: false,
  disableClearable: false,
  debounceDelay: 800,
  multiple: false,
  tabular: false,
  fieldHelperText:""
};
A_Autocomplete.propTypes = {
  defaultValue: PropTypes.string,
  edgeLabel: PropTypes.string,
  id: PropTypes.any,
  onChange: PropTypes.func,
  resultTemplate: PropTypes.any,
  destinationVertex: PropTypes.string,
  debounceDelay: PropTypes.any,
  error: PropTypes.bool,
  variant: PropTypes.any,
  required: PropTypes.bool,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  createNew: PropTypes.bool,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  filters: PropTypes.any,
  fullWidth: PropTypes.any,
  loogedInUserValue: PropTypes.bool,
  tabular: PropTypes.bool,
  edgeFilter: PropTypes.any,
  fieldHelperText:PropTypes.string
};
export default A_Autocomplete;

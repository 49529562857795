/* eslint-disable */
import * as crmEngineApis from "../../../../../../Utils/Helpers/crmDataApis/crmDataApis";
import {
  schedulerFrequency,
  schedulerRemainderTemplate,
} from "../../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../../Utils/apis/apis";
import { FromEpochFormat } from "../../../../../../Utils/Helpers/dateUtils";

export const getEcpochDateOfOptionSetMonth = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  try {
    let formDate = JSON.parse(date);
    let str = formDate.value;
    str = str.split(" ");
    const d = new Date();
    let month = str[0] ? months.indexOf(str[0]) : d.getMonth();
    let year = str[1] ? str[1] : d.getFullYear();
    let day = d.getDate();
    let newdate = Math.floor(
      new Date(year, month, day, 12, 0, 0).getTime() / 1000.0
    );
    return newdate;
  } catch {
    return Math.round(Date.now() / 1000);
  }
};

export const CRMEdgeVertexEngine = async (payload, restParams, crmId) => {
  try {
    payload && payload.properties
      ? (payload.properties["parentId"] = crmId)
      : "";
    let res = null;
    let actionType =
      restParams.entity &&
      restParams.entity.parameters &&
      restParams.entity.parameters.actionType
        ? restParams.entity.parameters.actionType
        : "";
    switch (actionType) {
      case "add":
        res = await InsertVertex(payload);
        break;
      case "link":
        res = await AddEdge(payload, restParams);
        break;
      case "inverse":
        res = await InsertWithAddEdgeInverse(payload, restParams);
        break;

      default:
        res = await InsertWithAddEdge(payload, restParams);
        break;
    }
    return res;
  } catch (e) {
    console.log(
      "Error occured in CRMEdgeVertexEngine while saving form: " + e.message
    );
    return { message: e.message };
  }
};

const AddEdge = async (payload, params) => {
  //==When in the view form parameter, sourceVertexId is available then
  //==linking suppose to done with addEdge api
  //==DestinationVertexId will be selected lookup value in the form
  try {
    let destinationEdgeLabel =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.destinationLabel
        ? params.entity.parameters.destinationLabel
        : "";
    destinationEdgeLabel =
      destinationEdgeLabel === "" &&
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.edgeLabel
        ? params.entity.parameters.edgeLabel
        : "";
    let edgeLabel = params.edgeLabel ? params.edgeLabel : "";
    let props =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.isDealTeam
        ? {
            role:
              payload && payload.properties && payload.properties.role
                ? payload.properties.role
                : "",
            userRole:
              payload && payload.properties && payload.properties.userRole
                ? payload.properties.userRole
                : "",
            user:
              payload && payload.properties && payload.properties.dealTeamRadio
                ? payload.properties.dealTeamRadio
                : "",
            companyRole:
              payload && payload.properties && payload.properties.companyRole
                ? payload.properties.companyRole
                : "",
          }
        : payload.properties;
    let _sourceVertextId = params.entity.parameters.sourceVertexId;
    let _destinationVertextId = getDestinationVertexIdBasedOnDestinationLabel(
      payload.edges,
      destinationEdgeLabel
    );
    //edgeLabel
    let _edgeLabel = getEdgeLableData(
      params.showPopup,
      params.isAddEdge,
      edgeLabel
    );

    _edgeLabel = _edgeLabel === "" ? destinationEdgeLabel : "";

    const addEdgePayLoad = {
      sourceVertexId: _sourceVertextId,
      destinationVertexId: _destinationVertextId,
      edgeLabel: _edgeLabel,
      properties: props,
    };
    let res = await crmEngineApis.AddEdge(addEdgePayLoad);
    return res;
  } catch (e) {
    console.log("Error occured in AddEdge while saving form: " + e.message);
    return { message: e.message };
  }
};

const getDestinationVertexIdBasedOnDestinationLabel = (
  edge,
  destinationLabel
) => {
  try {
    let destinationVId = "";
    for (let i in edge) {
      if (edge[i].label === destinationLabel) {
        destinationVId = edge[i].guid[0];
      }
    }
    destinationVId = destinationVId !== "" ? edge[0].guid[0] : "";
    return destinationVId;
  } catch (e) {
    console.log(
      "Error occured in getDestinationVertexIdBasedOnDestinationLabel: " +
        e.message
    );
    return { message: e.message };
  }
};

export const getEdgeLableData = (showPopup, isAddEdge, edge, edgeLabel) => {
  try {
    let edgelabelData = "";
    if (showPopup == true || isAddEdge === true) {
      if (isAddEdge === true) {
        for (let i in edge) {
          edgelabelData = edge[i].label ? edge[i].label : "";
          break;
        }
      }
      edgelabelData = edgelabelData
        ? edgelabelData
        : edgeLabel
        ? edgeLabel
        : "";
    }
    return edgelabelData;
  } catch (e) {
    console.log("Error occured in getEdgeLableData: " + e.message);
    return { message: e.message };
  }
};

export const getDefaultValues = (steps) => {
  try {
    let elementsWithDefaultValues = [];
    for (let i = 0; i < steps.length; i++) {
      let step = steps[i];
      if ("content" in step) {
        let elements = step.content.filter((ele) => {
          return ele.defaultValue && ele.defaultValue !== "";
        });
        if (elements.length > 0) {
          elementsWithDefaultValues =
            elementsWithDefaultValues.concat(elements);
        }
      }
    }
    return elementsWithDefaultValues;
  } catch (e) {
    console.log("Error occured in getDefaultValues: " + e.message);
    return { message: e.message };
  }
};

export const setEdgeLabelData = (newFormData) => {
  try {
    let edgearr = [];
    for (const key in newFormData) {
      if (
        key &&
        newFormData[key] &&
        typeof newFormData[key] == "object" &&
        newFormData[key]["fieldType"] &&
        newFormData[key]["fieldType"] == "lookup"
      ) {
        let arrayValue = [];
        if (newFormData[key].data.length > 0) {
          newFormData[key].data.forEach((val) => {
            arrayValue.push(val.id);
          });
        } else {
          arrayValue.push(newFormData[key].data.id);
        }
        let resEdgeObject = {
          label: newFormData[key]["edgeLabel"],
          guid: arrayValue,
        };
        edgearr.push(resEdgeObject);
        newFormData[key] = undefined;
      }
    }
    return edgearr;
  } catch (e) {
    console.log("Error occured in setEdgeLabelData: " + e.message);
    return { message: e.message };
  }
};

const InsertVertex = async (payload) => {
  try {
    let res = await crmEngineApis.CreateDestinationVertex(payload);
    return res;
  } catch (e) {
    console.log("Error occured in InsertVertex: " + e.message);
    return { message: e.message };
  }
};
const InsertWithAddEdge = async (payload, params) => {
  try {
    let res = await crmEngineApis.CreateDestinationVertex(payload);
    let sourceVertexId =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.sourceVertexId
        ? params.entity.parameters.sourceVertexId
        : "";
    if (sourceVertexId) {
      const addEdgePayLoad = {
        sourceVertexId: sourceVertexId,
        destinationVertexId: res.id,
        edgeLabel: params.entity.parameters.edgeLabel
          ? params.entity.parameters.edgeLabel
          : res && res.edges && res.edges.length > 0 && res.edges[0].label
          ? res.edges[0].label
          : "",
        properties: payload.properties ? payload.properties : {},
      };
      await crmEngineApis.AddEdge(addEdgePayLoad);
    }

    return res;
  } catch (e) {
    console.log("Error occured in InsertWithAddEdge: " + e.message);
    return { message: e.message };
  }
};

export const getStepContent = (fields, index, steps) => {
  let stepElements = [];
  let elementsProps = [];
  let contentRaw = steps[index];
  if (contentRaw) {
    if ("content" in contentRaw) {
      stepElements = contentRaw.content;
    } else if ("sections" in contentRaw) {
      stepElements = contentRaw.sections;
    } else if ("fields" in contentRaw) {
      stepElements = contentRaw.fields;
    }
    elementsProps = stepElements.map((element) => {
      let elementProps = {};
      let fieldProperties = fields.filter((field) => {
        return element.fieldId === field.id;
      })[0];

      if (fieldProperties !== undefined) {
        elementProps = { ...fieldProperties, ...element };
      } else {
        elementProps = element;
      }
      return elementProps;
    });
  }

  elementsProps.push({
    fieldType: "permissions",
  });

  return elementsProps;
};
export const schedulerCustomPayload = async (payload, crmId, entity) => {
  let data = await payload.properties;
  for (let i in data) {
    if (i === "frequency") {
      for (let char in schedulerFrequency) {
        if (char === data[i]) {
          data.FrequencyUnit = schedulerFrequency[char];
          break;
        }
      }
    } else if (i === "ReminderTemplateName") {
      for (let char in schedulerRemainderTemplate) {
        if (char === data[i]) {
          data.ReminderTemplateId = schedulerRemainderTemplate[char];
          break;
        }
      }
    }
  }
  entity &&
    entity.parameters &&
    entity.parameters.excelProcessDates &&
    entity.parameters.excelProcessDates.split(",").map((property) => {
      if (data && data[property]) {
        let dateEpoch = FromEpochFormat(data[property]);
        let date = new Date(dateEpoch);
        data[property] = date.toISOString();
      }
    });
  data.PortFolioCompanyID = crmId;
  let Payload = {
    id: crmId,
    label:
      entity && entity.parameters && entity.parameters.pageUrl
        ? entity.parameters.pageUrl
        : payload && payload.label
        ? payload.label
        : "",
    properties: {
      _partitionKey: crmId,
    },
  };
  let resultData = await httpPost(`/CRMData/getRecordByProperty`, Payload);

  if (resultData && resultData.length > 0) {
    data.Portfolio = resultData[0].properties["Investment Name"]
      ? resultData[0].properties["Investment Name"]
      : resultData[0].properties.projectName;
  }
  data.isDeleted = false;
  data.parameters = {
    user: data.user
      ? data.user
      : entity && entity.formData && entity.formData.user
      ? entity.formData.user
      : "",
    company: data.company
      ? data.company
      : entity && entity.formData && entity.formData.company
      ? entity.formData.company
      : "",
    link: data.link
      ? data.link
      : entity && entity.formData && entity.formData.link
      ? entity.formData.link
      : "",
    buttonText: data.buttonText
      ? data.buttonText
      : entity && entity.formData && entity.formData.buttonText
      ? entity.formData.buttonText
      : "",
  };
  data.parameters = JSON.stringify(data.parameters);

  payload.properties = data;
  return payload;
};

const InsertWithAddEdgeInverse = async (payload, params) => {
  try {
    let res = await crmEngineApis.CreateDestinationVertex(payload);
    let sourceVertexId = "";
    if (
      params &&
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.sourceVertexLookupLabel
    ) {
      for (let i in payload.edges) {
        if (
          payload.edges[i].label ===
          params.entity.parameters.sourceVertexLookupLabel
        ) {
          sourceVertexId = payload.edges[i].guid[0];
        }
      }
    }
    if (sourceVertexId) {
      const addEdgePayLoad = {
        sourceVertexId: sourceVertexId,
        destinationVertexId: res.id,
        edgeLabel: params.entity.parameters.edgeLabel
          ? params.entity.parameters.edgeLabel
          : res && res.edges && res.edges.length > 0 && res.edges[0].label
          ? res.edges[0].label
          : "",
        properties: payload.properties ? payload.properties : {},
      };
      await crmEngineApis.AddEdge(addEdgePayLoad);
    }

    return res;
  } catch (e) {
    console.log("Error occured in InsertWithAddEdgeInverse: " + e.message);
    return { message: e.message };
  }
};

export const getIdsFromEdges = (edges, edgeLabels) => {
  try {
    let IDsCollection = [];
    edges.forEach((edge) => {
      if (edgeLabels.includes(edge.label)) {
        IDsCollection = [...IDsCollection, ...edge.guid];
      }
    });
    return IDsCollection;
  } catch (error) {
    return [];
  }
};
// This function is applicable to get any key from any json string or object this code can handle null checks as well
export const getObjectFromJSONString = (
  JSONData,
  key,
  defaultValue = false
) => {
  let evaluatedValue = defaultValue;
  try {
    let inputStringAfterParsing =
      typeof JSONData === "string" ? JSON.parse(JSONData) : JSONData;
    evaluatedValue = eval(`inputStringAfterParsing${key}`);
  } catch (error) {}
  return evaluatedValue;
};

/* eslint-disable */
import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { previousUploadActionTypes } from "../actions/actionTypes";
import * as previousUploadActions from "../actions/previousUploadActions";
import { setToast } from "../actions/toastActions";
import { toastType, toastSeverity } from "../../Utils/config/toastConfig";
import { message } from "../../Utils/config/messages";
import { fileListWithDisplayName } from "../../components/Factory/Molecules/Common/Renderers/Views/PreviousTemplate/previousUpload.helper";
export function* getPreviousUploadSaga() {
  while (true) {
    const action = yield take(previousUploadActionTypes.GET_PREVIOUS_UPLOAD);
    const { crmId, pageUrl, container, directory } = action.payload;

    try {
      const url = `${process.env.DOCUMENT_HELPER}`;
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      // TODO - Refactor header
      let documentName;
      documentName = crmId == 0 ? pageUrl : crmId;
      documentName = directory ? directory : documentName;
      let header = {
        filename: documentName,
        type: container ? container : "generic",
        "file-operation": "blob-items",
        "process-file": "no",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let config = commonFunctions.getHttpConfig(header);

      const res = yield axios.post(
        url,
        {
          method: "GET",
        },
        config
      );
      let convertedData = [];
      if (res.status === 200) {
        convertedData = res.data;
      }
      let filesList = fileListWithDisplayName(convertedData);
      yield put(
        previousUploadActions.getPreviousUploadSucceeded({
          data: filesList,
        })
      );
    } catch (e) {
      //TODO - set toast message here
      //TODO - get errorcode message from message file using function
      // TODO - check status code in case of error, success or anything
      // pass status code to a generic function and that will loop through errorcode
      // and will return appropriate message for api

      setToast({
        type: toastType.PREVIOUS_UPLOAD_SAGA,
        severity: toastSeverity.ERROR,
        message: message.PREVIOUS_UPLOAD_SAGA,
        status: true,
      });
      previousUploadActions.getPreviousUploadFailed({
        errorCode: "",
        message: "",
        status: false,
      });
    }
  }
}

import { investorType, RelationShip } from "./FieldsForIRReports";

export const irRePortFormEnums = {
    HAS_COUNTRY: "has_country",
    COUNTRY: "Country",
    OWNER: "owner",
    COVERAGE: "Coverage",
    INVESTOR_TYPE_LABEL: "Investor Types",
    INVESTOR_TYPE_NAME: "Asp_InvestorTypeName",
    RELATIONSHIP_LABEL: "Relationship",
    RELATIONSHIP_NAME: "pAspInvestorPriorityName",

    STRING: "String",
    NUMERIC: "(text Input)Numeric",
    HEADING: "Heading",
    CHECKBOX: "Checkbox"
}



const getCheckboxValue = (value) => {
    return value == 1 ? "Yes" : value == 0 ? "No" : "Unknown"
}

const getValuetoId = (val, array) => {
    
    try{
        let valDecoded = JSON.parse(val);
        for (const element of array) {
            if (valDecoded && valDecoded.Id && valDecoded.value == element.id) {
                return element.label
            }
        } 
    }
    catch(err){
        return " "
    }
}

const parseGeoLocation = (locationString) => {
    try{
        let coOrds = JSON.parse(locationString); 
        return coOrds;
    } 
    catch(e){
        return {lat : null, lng : null};
    }
}

const calculateWidthForExcel = (a,b) => {
    Object.keys(a).map((i) => {
        if(a[i] < b[i].length){
            a[i] = b[i].length;
        }
    });
    return a;
}

export const getInvestmentListArray = (listData) => {
    
    let investorListWidthData = {
        "Investor" : 10,
        "Relationship" : 12,
        "City": 10,
        "Country": 10,
        "Coverage": 10,
        "Type": 10,
        "Bite Size Min": 12,
        "Bite Size Max": 12,
        "PE": 10,
        "AOF":10,
        "AFE":10
    };
    const investorList=[]
    const heatMap=[]
     listData.forEach(val => {
        
        investorList.push({
            "Investor": `<a href='/company/${val.properties.id}'>${val.properties.Name}</a>` || " ",
            "Relationship": getValuetoId(val.properties.Ana_InvestorPriority_Name, RelationShip) || " ",
            "City": val.properties.City || " ",
            "Country": val.properties.country || " ",
            "Coverage": `${val.properties.CoverageFirstName|| ""} ${val.properties.CoverageLastName|| ""}`,
            "Type": getValuetoId(val.properties.Asp_InvestorType_Name, investorType) || " ",
            "Bite Size Min": val.properties.Asp_BiteSizeMin || " ",
            "Bite Size Max": val.properties.Asp_BiteSizeMax || " ",
            "PE": getCheckboxValue(val.properties.Ana_InterestinPEFund || " "),
            "AOF": getCheckboxValue(val.properties.Ana_InterestinDebtFund || " "),
            "AFE": getCheckboxValue(val.properties.ana_InterestinCreditIncomeFund || " ")
        })
        investorListWidthData = calculateWidthForExcel(investorListWidthData, investorList[investorList.length-1]);
        heatMap.push({
            "geolocation":{...parseGeoLocation(val.properties.geolocation)},
            "Investor": val.properties.Name || " ",
            "Type": getValuetoId(val.properties.Asp_InvestorType_Name, investorType) || " ",
            "PE": val.properties.Ana_InterestinPEFund,
            "AOF": val.properties.Ana_InterestinDebtFund,
            "AFE": val.properties.ana_InterestinCreditIncomeFund
        })
    })
   return {investorList,heatMap,investorListWidthData}
}

export const getMailingListArray = (listData) => {
    let mailingListArray = []
    let mailingListWidthData = {
        "First name": 10,
        "Surname": 10,
        "Company": 10,
        "Email address": 13,
        "Comments": 10,
    };
    listData.forEach((val) =>{
        mailingListArray.push({
            "First name": val.properties.FirstName || " ",
            "Surname": val.properties.LastName || " ",
            "Company": val.properties.Company || " ",
            "Email address": val.properties.Email || " ",
            "Comments": val.properties.Comment || "",
        });
        mailingListWidthData = calculateWidthForExcel(mailingListWidthData, mailingListArray[mailingListArray.length-1]);
    });

    return {mailingListArray, mailingListWidthData};
}

/* eslint-disable react/prop-types */
import React from 'react'
import { useEffect } from 'react';
import { httpPost } from '../../../../../../../Utils/apis/apis';
import { getHttpConfig } from '../../../../../../../Utils/Helpers/common';
import A_SimpleDialog from '../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog'
import A_CircularIndeternment from '../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment';
import A_Button from '../../../../../Atoms/Inputs/Buttons/A_Button'
import A_CustomSelect from '../../../../../Atoms/Inputs/Selects/A_CustomSelect';
import M_RadiosGroupConfig from '../../../RadioGroup/M_RadiosGroupConfig';
import { dataFun, payload, radioOptions } from './JLvaluationReport.Helper';

function JlvaluationReport(props) {
    const [open, setOpen] = React.useState(false);
    const [formFields, setformFields] = React.useState([]);

    const handleOpenModal = () => {
        setOpen(true)
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    const getFormFileds = async () => {
        const response = await httpPost('/SQLQuery/getDataFromMultipleSPs?chartSPs=true', payload)
        if (!response.message) {
            const data = dataFun(response)
            setformFields(data)
        } else {
            setformFields([])
        }

    }

    useEffect(() => {
        getFormFileds()
    }, [])

    const ModalContent = () => {
        const [reportOption, setReportOption] = React.useState({ pptName: "AssetCo", pptId: "489c44e0-41c3-4687-a248-dd549477b311" })
        const [pptLoading, setPptLoading] = React.useState(false);

        const [formValue, setformValue] = React.useState({
            "Currency": "",
            "Quarter1": "",
            "Quarter2": "",
            "SortOrder": "",
        });

        const [validation, setvalidation] = React.useState({});
        const changeHandler = (name, value) => {
            setformValue({ ...formValue, [name]: value })
            setvalidation({ ...validation, [name]: '' });

        }
        const radioChange = ({ label, value }) => {
            setReportOption({ pptName: label, pptId: value })
        }

        const hadlevalidation = () => {
            let isValid = true;
            const _validation = {}
            Object.entries(formValue).forEach(([key, value]) => {
                if (!value) {
                    isValid = false;
                    _validation[key] = `${key} is Required.`
                }
            })
            if (!isValid) {
                setvalidation(_validation);
                return false
            }
            return true
        }
        const submitHandler = async () => {
            if (hadlevalidation()) {
                setPptLoading(true)
                const { pptName, pptId } = reportOption
                let config = getHttpConfig();
                const response = await httpPost(`${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`, { "parameters": { }, "commonParameters": formValue }, {
                    ...config,
                    responseType: "blob",
                });
                setPptLoading(false)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", pptName + ".pptx");
                link.click();
                !pptLoading && handleCloseModal()
            }
        }

        return (<>
            {formFields && formFields.length > 0 ? <div>
                <div className='asdas'>
                    <M_RadiosGroupConfig
                        value={reportOption.pptId}
                        error={false}
                        defaultValue={reportOption.pptId}
                        native={true}
                        align="horizontal"
                        options={radioOptions}
                        onChange={radioChange}
                    />
                </div>
                {formFields.map((option, index) => {
                    return (<A_CustomSelect key={`${index}_${option.label}`} name={option.label} helperText={validation[option.label]} lable={option.label} options={option.options} onChange={changeHandler} />)
                })}
                <div style={{ textAlign: "right", display: 'block' }} >
                    <A_Button color="primary" onClick={submitHandler} label={"Generate Report"} disabled={
                        pptLoading ? true : false
                    } endIcon={
                        pptLoading ? (
                            <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                            <></>
                        )
                    } />
                </div>
            </div> : <><p>No data found</p></>}
        </>
        )
    }
    return (
        <div><A_Button
            label={props.lable || ""}
            color="primary"
            onClick={handleOpenModal}
        />
            <A_SimpleDialog open={open} showclosebutton={"true"} title={"jl valuation filter report"} onClose={handleCloseModal} dialogContent={<ModalContent />}>

            </A_SimpleDialog>
        </div>
    )
}

export default JlvaluationReport

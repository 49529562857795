/* eslint-disable */
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";

const colorValue = [
  {
    name: "black",
    value: 4,
  },
  {
    name: "green",
    value: 1,
  },
  {
    name: "#ffbf00",
    value: 2,
  },
  {
    name: "amber",
    value: 2,
  },
  {
    name: "red",
    value: 3,
  },
];

const isJSON = (str) => {
  if (typeof str === "string") {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {}
  }
  return false;
};

const getColorValue = (color) => {
  const colorVal = color && color.toLowerCase();
  const val = colorValue.filter((item) =>
    item.name.includes(colorVal && colorVal.replace(" ", ""))
  );
  const firstValue = val && val.length > 0 ? val[0] && val[0].value : 0;
  return firstValue;
};

const getColor = (value) => {
  const regex =
    value && value.includes("color")
      ? /color\s*:\s*([^;]+)/i
      : /background-color\s*:\s*([^;]+)/i;
  const str = value && value.match(regex);
  const colorStr = str && str[0];
  const color = colorStr && colorStr.split(":") && colorStr.split(":")[1];
  return color;
};

const removeCurrency = (value) => {
  const ScRe =
    /[\$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;
  const finalValue =
    value &&
    value.toString().replace(ScRe, "") &&
    value.toString().replace(ScRe, "").replace(",", "");
  return finalValue;
};

function isHTML(str) {
  try {
    var a = document.createElement("div");
    a.innerHTML = str;
    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }
  } catch {
    return false;
  }
  return false;
}
const descendingComparator = (a, b, orderBy) => {
  let firstNum;
  let secNum;
  let firstVal;
  let secondVal;
  let firstStr;
  let secondStr;
  const typeOptionSet =
    (a[orderBy] && isJSON(a[orderBy]) && a[orderBy].includes("label")) ||
    (b[orderBy] && isJSON(b[orderBy]) && b[orderBy].includes("label"));
  const typeRAG =
    (a[orderBy] && isHTML(a[orderBy]) && a[orderBy].includes("color")) ||
    (b[orderBy] && isHTML(a[orderBy]) && b[orderBy].includes("color")) ||
    (a[orderBy] &&
      isHTML(a[orderBy]) &&
      a[orderBy].includes("background-color")) ||
    (b[orderBy] &&
      isHTML(a[orderBy]) &&
      b[orderBy].includes("background-color"));
  const RAGvalue = orderBy && orderBy == "RAG";

  if (orderBy) {
    if (typeOptionSet) {
      firstStr = handleOptionSetValue(a[orderBy]).toLowerCase();
      secondStr = handleOptionSetValue(b[orderBy]).toLowerCase();
      if (firstStr === "" && secondStr === "") return 0;
      else if (firstStr === "" && secondStr !== "") return -1;
      else if (firstStr !== "" && secondStr === "") return 1;
      else if (secondStr < firstStr) return -1;
      else if (secondStr > firstStr) return 1;
      else return 0;
    } else if (typeRAG || RAGvalue) {
      let firstColor =
        typeRAG && getColor(a[orderBy]) && getColor(a[orderBy]).toLowerCase();
      let secondColor =
        typeRAG && getColor(b[orderBy]) && getColor(b[orderBy]).toLowerCase();
      firstNum = RAGvalue
        ? getColorValue(a[orderBy])
        : getColorValue(firstColor);
      secNum = RAGvalue
        ? getColorValue(b[orderBy])
        : getColorValue(secondColor);
      if (secNum < firstNum) return 1;
      else if (secNum > firstNum) return -1;
      else return 0;
    } else {
      firstNum = removeCurrency(a[orderBy]);
      secNum = removeCurrency(b[orderBy]);
      firstVal = !isNaN(firstNum);
      secondVal = !isNaN(secNum);

      if (firstVal && secondVal) {
        return Number(firstNum) - Number(secNum);
      }
      if (firstNum === 0 && secNum === 0) return 0;
      else if (firstNum === 0 && secNum !== 0) return -1;
      else if (firstNum !== 0 && secNum === 0) return 1;
      else if (firstVal) return -1;
      else if (secondVal) return 1;

      firstStr = a[orderBy] && String(a[orderBy]).toLowerCase();
      secondStr = b[orderBy] && String(b[orderBy]).toLowerCase();
      if (firstStr === "" && secondStr === "") return 0;
      else if (firstStr === "" && secondStr !== "") return -1;
      else if (firstStr !== "" && secondStr === "") return 1;
      else if (secondStr < firstStr) return -1;
      else if (secondStr > firstStr) return 1;
      else return 0;
    }
  }
  return false;
};

export default descendingComparator;

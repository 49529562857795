

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  statusBar: {
    overflow: "auto",
    height: 40,
    margin: "5px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  statusBarMobile:{
   width:"95dvw",
   height:"3vh",
   display:"flex",
   overflow:"hidden",
   fontSize:"7px"
  },
  stepWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  stepWrapperKiiled: {
    display: "flex",
    flexDirection: "row",
    width: "5%",

  },
  statusStep: {
    padding: "7px 15px",
    color: "white",
    flexGrow: 1,
  },
  stepWrapperBoxMobile: {
    display: "flex",
    flexDirection: "row",
    width: "30%",
  },

  statusCompleted: {
    background: "#263440",
  },
  statusInProgress: {
    background: "#5d6a74",
  },
}));

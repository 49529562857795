/* eslint-disable  */
// TODO- as this file has multiple lint issues so skip this file for lint velidation
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import M_GraphChart from "./GraphCharts/M_GraphChart";
import M_TableChart from "./M_TableCharts/M_TableCharts";
import { invoice } from "./ChartCardHelper";
import Skeleton from "@material-ui/lab/Skeleton";
import ErrorBoundary from "../../../../../Molecules/Common/error/ErrorBoundary";

import { useParams } from "react-router";
import {
  verifyValidChartRequest,
  hasPageFilter,
  toolbarFilterData,
} from "./M_ChartCard.Helper";
import M_CardFilter from "../../../../../atoms/Inputs/Selects/M_CardFilter";
import { FromEpochFormat } from "../../../../../../../Utils/Helpers/dateUtils";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import O_Pdfchart from "../../../../../Organisms/Common/pdfchart/O_Pdfchart";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ErrorMsg,
  filterRange,
} from "../../../../../../../Utils/config/config";
import { epochToDateFormatHelper } from "../../../../../../../Utils/Helpers/dateUtils";
const useStyles = makeStyles((theme) => ({
  downloadBtn: {
    position: "absolute",
    right: "60px",
    cursor: "pointer",
    zIndex: "9999999",
  },
  btnEndIcon: {
    color: "#1976D2",
    width: "21px !important",
    height: "21px !important",
    marginTop: "0.3em",
    position: "fixed",
  },
  btnEndIconswitch: {
    color: "#1976D2",
    width: "21px !important",
    height: "21px !important",
    marginTop: "-0.87em",
    position: "fixed",
  },
}));

//TODO - M_ChartCard renders two times or multiple for single chart, need to fix it: It causes multiple API calls
const M_ChartCard = React.memo((props) => {
  const classes = useStyles();
  const [chart, setChart] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filterData, setFilterData] = useState(undefined);
  const { crmId, pageUrl, templateId } = useParams();
  const pageFilters = props.toolbarSelections.filters;
  const chartFilterState = props.chartFilter;
  const [chartLoading, setChartLoading] = useState(false);
  const templateFilterData = toolbarFilterData(props, templateId);
  const currentDealid =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data.Deal &&
    props.currentPage.data.Deal &&
    JSON.parse(
      props &&
        props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data.Deal &&
        props.currentPage.data.Deal
    )[0].id;
  useEffect(() => {
    if (pageUrl === invoice.INBOUND_INVOICE) {
      props.content.content.sourceId = currentDealid;
      props.content.parameters.dealId = currentDealid;
      props.content.parameters.invoiceId = crmId;
    }
  }, []);
  useEffect(() => {
    //setChart(sampleGraphChart);
    if (props.content && props.content.parameters && crmId) {
      for (const key in props.content.parameters) {
        if (props.content.parameters[key] == "=crmId") {
          props.content.parameters[key] = crmId;
        } else if (props.content.parameters[key] == "=currentDate") {
          let dateObj = new Date();
          let day = dateObj.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let month = (dateObj.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let dateValue = `${day}/${month}/${dateObj.getFullYear()}`;
          props.content.parameters[key] = dateValue;
        } else if (props.content.parameters[key] == "=lastYearDate") {
          let dateObj = new Date();
          // to get one year back date from now including current date
          dateObj.setDate(dateObj.getDate() + 1);
          dateObj.setFullYear(dateObj.getFullYear() - 1);
          // convert single digit number to double digits
          let day = dateObj.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let month = (dateObj.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let dateValue = `${day}/${month}/${dateObj.getFullYear()}`;
          props.content.parameters[key] = dateValue;
        }
      }
    }
    if (
      props.content &&
      props.content.content &&
      props.content.content.sourceId &&
      crmId
    ) {
      for (const key in props.content.content.sourceId) {
        if (props.content.content.sourceId == "=crmId") {
          props.content.content.sourceId = crmId;
        }
      }
    }
    let mainDataSet = props.currentPage.data;
    let parameterKeys = props.content.parameters;
    for (let i in parameterKeys) {
      if (
        typeof parameterKeys[i] == "string" &&
        parameterKeys[i].charAt(0) === "="
      ) {
        parameterKeys[i] = parameterKeys[i].substring(1);
        if (mainDataSet && mainDataSet.hasOwnProperty(parameterKeys[i])) {
          parameterKeys[i] = mainDataSet[parameterKeys[i]];
        } else {
          parameterKeys[i] = "";
        }
      }
    }
    if (templateFilterData.length > 0) {
      if (templateFilterData.length === pageFilters.length) {
        getChart(props.content, true);
      }
    } else {
      getChart(props.content, true);
    }
  }, [chart, pageFilters]);

  const callback = () => {
    getChart(props.content, true, true);
  };

  // useEffect(() => {
  //   getChart(props.content, true);
  // }, [pageFilters]);

  const getChart = async (chartRequest, filtersChanged, skipValidation) => {
    if (props && props.chartFilter) {
      props.chartFilter.map((item) => {
        localStorage.removeItem(item.id);
      });
    }
    if (!chart || filtersChanged) {
      let url = "/ChartData/getchartdata";
      let requestPayload = JSON.parse(JSON.stringify(chartRequest));
      //Add updated params to chart request
      if (pageFilters && hasPageFilter(props.currentPage)) {
        pageFilters.forEach((pageFilter) => {
          if (pageFilter.type && pageFilter.type === filterRange.RANGE) {
            requestPayload.parameters["to"] = pageFilter.to;
            // requestPayload.parameters["filterOnDateField"] =
            //   pageFilter && pageFilter.filterOnDateField
            //     ? pageFilter.filterOnDateField
            //     : undefined;
          }
          requestPayload.parameters[pageFilter.filterName] = pageFilter.value;
        });
      }
      if (!verifyValidChartRequest(requestPayload) && skipValidation !== true) {
        return;
      }
      setIsLoading(true);
      const result = await httpCall.httpPost(url, requestPayload);
      let chData = result;
      if ("message" in result) {
        setIsError(result);
      } else {
        setIsError(false);
        setChart(chData);
      }
      setIsLoading(false);
    }
  };
  const handleRefreshChart = (chartRequest) => {
    setChart(undefined);
    getChart(chartRequest);
  };
  const handleFilterChange = (key, value) => {
    props.content.parameters[key] = value;
    setChart(undefined);
    getChart(props.content);
  };

  let array = [];
  const handleCardLevelFilterData = async (obj, data) => {
    if (obj !== null) {
      array.push(obj);

      if (localStorage.getItem(obj.id)) {
        localStorage.setItem(obj.id, JSON.stringify(obj));
      } else {
        localStorage.setItem(obj.id, JSON.stringify(obj));
      }
      handleCardFilterChange(array);
    }
  };
  const handleCardFilterChange = async (array) => {
    if (array.length > 0) {
      setIsLoading(true);
      var dataTest = JSON.parse(JSON.stringify(chart));
      var dataTest1 = JSON.parse(JSON.stringify(dataTest));
      await props.chartFilter.map((item) => {
        let indxArray = [];
        let arr = [];
        let localValue = JSON.parse(localStorage.getItem(item.id));
        if (localValue !== null) {
          arr.push(localValue);
          if (arr[0].value !== "") {
            if (dataTest1.data.dimensions[0] === arr[0].id) {
              dataTest1.data.series.map((item, index) => {
                if (arr[0].date && arr[0].year) {
                  let date = new Date(FromEpochFormat(parseInt(item.name[0])));
                  let year = date.getFullYear();

                  if (year !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].month) {
                  let date = new Date(item.name[0]);

                  let month = date.toLocaleString("default", {
                    month: "short",
                  });

                  if (month !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].dateFormat) {
                  let dateFormat = epochToDateFormatHelper(
                    parseInt(item.data[indexOfProperties]),
                    arr[0].dateFormat
                  );

                  if (dateFormat !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else {
                  if (item.name[0] !== arr[0].value) {
                    indxArray.push(index);
                  }
                }
              });
            } else {
              let indexOfProperties = dataTest1.data.categories.indexOf(
                arr[0].id
              );

              dataTest1.data.series.map((item, index) => {
                if (arr[0].date && arr[0].year) {
                  let date = new Date(
                    FromEpochFormat(parseInt(item.data[indexOfProperties]))
                  );
                  let year = date.getFullYear();

                  if (year !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].month) {
                  let date = new Date(
                    FromEpochFormat(parseInt(item.data[indexOfProperties]))
                  );
                  let month = date.toLocaleString("default", {
                    month: "short",
                  });

                  if (month !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].dateFormat) {
                  let dateFormat = epochToDateFormatHelper(
                    parseInt(item.data[indexOfProperties]),
                    arr[0].dateFormat
                  );

                  if (dateFormat !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else {
                  if (item.data[indexOfProperties] !== arr[0].value) {
                    indxArray.push(index);
                  }
                }
              });
            }

            for (var i = indxArray.length - 1; i >= 0; i--) {
              dataTest1.data.series.splice(indxArray[i], 1);
            }
          }
        }
      });

      setFilterData(dataTest1);
      setIsLoading(false);
    }
  };

  const handleExcelDownload = async () => {
    setChartLoading(true);
    let convertedFormData = [
      {
        chartId: props.content.chartId || null,
        parameters: props.content.parameters || {},
        content: props.content.content,
        fileName: "excelFile",
        worksheetName: props.templateTitle || "",
      },
    ];
    let config = commonFunctions.getHttpConfig();
    const response = await httpCall.httpPost(
      `/ChartData/download-excel`,
      convertedFormData,
      { ...config, responseType: "blob" }
    );
    let cardTitle =
      props.chart && props.chart.chartConfiguration
        ? props.chart.chartConfiguration.chartName + ".xlsx"
        : "excelFile.xlsx";
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", cardTitle);
    link.click();
    setChartLoading(false);
  };

  return (
    <div
      style={{
        height: "auto",
        minHeight: `${props.Height}`,
      }}
    >
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}
      {isError && (
        <div>
          <h4>{ErrorMsg.ERROR}</h4>
          <p> Error : {isError.message}</p>
          <h5>{ErrorMsg.MESSAGE}</h5>
        </div>
      )}
      {!isLoading &&
        !isError &&
        chart &&
        chart.chartConfiguration &&
        chart.chartConfiguration.type === "graph" &&
        (props.content === undefined ||
          props.content.type === undefined ||
          props.content.type.toLowerCase() !== "customchart") && (
          <ErrorBoundary>
            <Box style={{ position: "relative" }}>
              {chartFilterState && chart ? (
                <M_CardFilter
                  chartFilter={props.chartFilter}
                  chart={chart}
                  handleCardLevelFilterData={handleCardLevelFilterData}
                />
              ) : (
                ""
              )}
            </Box>
            <M_GraphChart
              chartFilter={chartFilterState}
              chart={filterData ? filterData : chart}
              originalData={
                chart && chart.data && JSON.parse(JSON.stringify(chart.data))
              }
              chartRequest={props.content}
              refreshChart={handleRefreshChart}
            />
          </ErrorBoundary>
        )}
      {!isLoading &&
        !isError &&
        chart &&
        chart.chartConfiguration &&
        chart.chartConfiguration.type === "table" &&
        (props.content === undefined ||
          props.content.type === undefined ||
          props.content.type.toLowerCase() !== "customchart") && (
          <ErrorBoundary>
            {/* <Box>{props.chartFilter ? chartFilter(props.chartFilter) : ""}</Box> */}

            <Box style={{ position: "relative" }}>
              {chartFilterState && chart ? (
                <M_CardFilter
                  chartFilter={props.chartFilter}
                  chart={chart}
                  handleCardLevelFilterData={handleCardLevelFilterData}
                />
              ) : (
                ""
              )}
              <>
                {chart &&
                  chart.chartConfiguration &&
                  chart.chartConfiguration.configuration &&
                  JSON.parse(chart.chartConfiguration.configuration)
                    .popUpDownloadBtn && (
                    <div className={classes.downloadBtn}>
                      {chartLoading ? (
                        <CircularProgress
                          className={
                            props.header != "" && props.header
                              ? classes.btnEndIcon
                              : classes.btnEndIconswitch
                          }
                        />
                      ) : (
                        <GetAppIcon
                          onClick={handleExcelDownload}
                          className={
                            props.header != "" && props.header
                              ? classes.btnEndIcon
                              : classes.btnEndIconswitch
                          }
                        />
                      )}
                    </div>
                  )}
              </>
            </Box>
            <M_TableChart
              header={props.header ? props.header : null}
              chartFilter={chartFilterState}
              chart={filterData ? filterData : chart}
              callback={callback}
              originalData={
                chart && chart.data && JSON.parse(JSON.stringify(chart.data))
              }
              crmData={props.currentPage.data}
              handleFilterChange={handleFilterChange}
              parameters={
                props.content && props.content.parameters
                  ? props.content.parameters
                  : {}
              }
            />
          </ErrorBoundary>
        )}
      {!isLoading &&
        !isError &&
        chart &&
        chart.chartConfiguration &&
        props.content &&
        props.content.type &&
        props.content.type.toLowerCase() === "customchart" && (
          <ErrorBoundary>
            <Box>
              {chartFilterState && chart ? (
                <M_CardFilter
                  chartFilter={props.chartFilter}
                  chart={chart}
                  handleCardLevelFilterData={handleCardLevelFilterData}
                />
              ) : (
                ""
              )}
            </Box>
            <O_Pdfchart
              chartFilter={chartFilterState}
              chart={filterData ? filterData : chart}
              originalData={
                chart && chart.data && JSON.parse(JSON.stringify(chart.data))
              }
              crmData={props.currentPage.data}
              handleFilterChange={handleFilterChange}
              parameters={
                props.content && props.content.parameters
                  ? props.content.parameters
                  : {}
              }
            />
          </ErrorBoundary>
        )}
    </div>
  );
});
M_ChartCard.defaultProps = {
  content: {},
  templateTitle: "",
  excelDownload: false,
};
M_ChartCard.propTypes = {
  toolbarSelections: PropTypes,
  chartFilter: PropTypes,
  content: PropTypes,
  currentPage: PropTypes,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, undefined)(M_ChartCard);

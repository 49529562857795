import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      marginTop: "-0.4em",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3em",
    },
  },
  titleContent: {
    marginLeft:"12px"
  },
  title: {
    marginLeft: "5px",
    fontSize: "1.25rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px"
    },
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  titleSection: {
    padding: "10px",
  },
  seperator: {
    fontSize: "20px",
    marginBottom: "2px",
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    fontSize: "15px",
  },
  logo: {
    marginLeft: "6px",
  },
  folderlink: {
    paddingTop: "10px",
    alignItem: "center",
  },
}));

const M_TemplatePageTitle = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.titleWrapper}>
      {/* Commented due to moving icon from Template to card headers */}
      {/* 
      
      <div className={classes.logo}>
        <A_Avatar
          alt="Logo"
          text={props.title? props.title[0]: ""}
          src={logoImage}
          size={"medium"}
        />
      </div> 
      
      */}

      <div className={classes.titleContent}>
        <>
          {props.title && <span className={classes.title}>{props.title}</span>}
          {!props.title && props.toolbar.PageTitle ? (
            <span className={classes.title}>{props.toolbar.PageTitle}</span>
          ) : (
            ""
          )}
        </>
        {props.subtitle && (
          <>
            <ChevronRightIcon className={classes.seperator} />
            <span className={classes.subtitle}>{props.subtitle}</span>
          </>
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
M_TemplatePageTitle.propTypes = {
  toolbar: PropTypes.object,
  optionSets: PropTypes.array,
  currentPage: PropTypes.object,
  primary: PropTypes.object,
  logo: PropTypes.any,
  title: PropTypes.any,
  subtitle: PropTypes.any,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_TemplatePageTitle);

/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router";
import axios from "axios";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as XLSX from "xlsx";
import ChangeLogTitle from "./ChangeLogTitle";
import { epochToDateFormatHelper } from "../../../../../Utils/Helpers/dateUtils";
import { message } from "../../../../../Utils/config/messages";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },

  tableCellWidth: {
    minWidth: "200px",
  },
  tableCell: {
    minWidth: "200px",
    border: "none",
    paddingTop: "5px",
    paddingBottom: "0px",
    marginLeft: "100px",
    padding: "0",
  },
  title: {
    width: "100%",
    paddingTop: "8px",
  },
  searchBox: {
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
      marginRight: "142px",
      marginLeft: "35.3%",
      minWidth: "69%",
      position: "relative",
      right: "13%",
    },
    [theme.breakpoints.up("xl")]: {},
  },
}));

const ChangeLog = (props) => {
  let { crmId } = useParams();
  const classes = useStyles();
  const [selected] = React.useState([]);
  const [changeLogData, setChangeLogData] = React.useState([]);
  const [fieldName, setFieldName] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filterData, setFilterData] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    let res = {};
    const getReports = async () => {
      const url = `${process.env.CHANGE_LOG_URL}`;
      const httpConfig = commonFunctions.getHttpConfig();
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        crmId: crmId,
        "x-functions-key": process.env.CHANGE_LOG_KEY,
        subdomain: subdomain,
        Authorization: httpConfig.headers.Authorization,
      };
      axios
        .get(url, { headers: header })
        .then((response) => {
          res = response.data;
          setChangeLogData(response.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setChangeLogData([]);
        });
    };
    getReports();
    let fields = new Map();
    let templateJson;
    props.currentPage.templates.map((item) => {
      typeof item.templateJson == "string" && item.templateJson !== ""
        ? (templateJson = JSON.parse(item.templateJson))
        : (templateJson = item.templateJson);
      templateJson && templateJson.cards
        ? templateJson.cards.map((card) => {
            if (card.content.type === "crmdata") {
              card &&
              card.content &&
              card.content.content &&
              card.content.content.fields
                ? card.content.content.fields.map((field) => {
                    fields.set(field.id, field.label);
                  })
                : card.content.content.tabs
                ? card.content.content.tabs.map((tab) => {
                    tab.content &&
                      tab.content.fields &&
                      tab.content.fields.map((field) => {
                        fields.set(field.id, field.label);
                      });
                  })
                : "";
            }
          })
        : "";
    });
    setFieldName(fields);
    // templateData.map
  }, []);

  const formattedData = (item) => {
    var date = item * 1000;
    const dateObject = new Date(date);
    const humanDateFormat = dateObject.toLocaleString();
    return <>{humanDateFormat}</>;
  };

  function isHTML(str) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
  }

  const handleSearchChange = (event) => {
    if (!event.target || !event.target.value || event.target.value == "") {
      setFilterData(changeLogData);
      return;
    }

    let valuSearch = event.target.value;
    let results = [];
    for (var i = 0; i < changeLogData.length; i++) {
      for (let key in changeLogData[i]) {
        if (
          changeLogData[i][key] &&
          changeLogData[i][key]
            .toString()
            .toLowerCase()
            .indexOf(valuSearch.toLowerCase()) != -1
        ) {
          results.push(changeLogData[i]);
          break;
        }
      }
    }
    setSearchValue(valuSearch);
    setFilterData(results);
  };

  const onExcelDownload = () => {
    let jsonData = [];
    let fileName = "";
    jsonData =
      changeLogData &&
      changeLogData.map((item) => {
        let newItem = { ...item };
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            let arr = item[key];
            arr &&
              arr.length > 0 &&
              arr.map((item, index) => {
                arr[index] =
                  typeof item == "number"
                    ? epochToDateFormatHelper(
                        Number(new Date(item)),
                        "MM/DD/YYYY HH:mm:ss"
                      )
                    : isHTML(item)
                    ? item && item.replace(/<[^>]+>|&nbsp;/g, "")
                    : item === null
                    ? "-"
                    : item;
              });
            newItem[key] = arr && arr.join(", ");
          } else if (key === "modifiedDate") {
            newItem[key] = epochToDateFormatHelper(
              Number(new Date(item[key])),
              "MM/DD/YYYY HH:mm:ss"
            );
          }
        });
        return newItem;
      });
    fileName = "Record Audit Trail.xlsx";
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Write the workbook to a file

    XLSX.writeFile(workbook, fileName);
  };

  const dataShown = searchValue ? filterData : changeLogData;
  const notFoundMsg = searchValue
    ? message.NO_SEARCH_FOUND
    : message.DATA_NOT_FOUND;

  return (
    <div>
      <Paper className={classes.paper}>
        <ChangeLogTitle
          numSelected={selected.length}
          handleSearchChange={handleSearchChange}
          excelDownload={onExcelDownload}
          classes={classes}
        />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : dataShown.length > 0 ? (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableCell>Modified By</TableCell>
                <TableCell>Modified Date</TableCell>
                <TableCell>Field Changed</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>Previous Value</TableCell>
              </TableHead>
              <TableBody>
                {dataShown.map((item) => (
                  <TableRow key={item} hover role="checkbox">
                    <TableCell className={classes.tableCellWidth}>
                      {item.modifiedBy}
                    </TableCell>
                    <TableCell className={classes.tableCellWidth}>
                      {formattedData(item.modifiedDate)}
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.fieldChanged.map((item1) => (
                          <TableRow key={item1}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {fieldName.get(item1)
                                ? fieldName.get(item1)
                                : item1}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.newValue.map((item) => (
                          <TableRow key={item}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {item == null || item == "" ? (
                                "-"
                              ) : typeof item == "number" ? (
                                formattedData(item)
                              ) : isHTML(item) ? (
                                <div
                                  dangerouslySetInnerHTML={{ __html: item }}
                                ></div>
                              ) : (
                                item
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.previousValue.map((item) => (
                          <TableRow key={item}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {item == null || item == "" ? (
                                "-"
                              ) : typeof item == "number" ? (
                                formattedData(item)
                              ) : isHTML(item) ? (
                                <div
                                  dangerouslySetInnerHTML={{ __html: item }}
                                ></div>
                              ) : (
                                item
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{ paddingBottom: "8px" }}>
            <center>{notFoundMsg}</center>
          </div>
        )}
      </Paper>
    </div>
  );
};
export default ChangeLog;

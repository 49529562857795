/* eslint-disable */
import { take } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { previousUploadDeleteActionTypes } from "../actions/actionTypes";
import * as previousUploadDeleteActions from "../actions/previousUploadDeleteActions";
import { setToast } from "../actions/toastActions";
import { toastType, toastSeverity } from "../../Utils/config/toastConfig";
import { message } from "../../Utils/config/messages";
export function* getPreviousUploadDeleteSaga() {
  while (true) {
    const action = yield take(
      previousUploadDeleteActionTypes.GET_PREVIOUS_UPLOAD_DELETE
    );
    const { documentName, fileOperation, containerType } = action.payload;
    try {
      const url = `${process.env.DOCUMENT_HELPER}`;
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        filename: documentName,
        type: containerType ? containerType : "generic",
        "file-operation": fileOperation,
        "process-file": "no",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let config = commonFunctions.getHttpConfig(header);

      yield axios.post(
        url,
        {
          method: "GET",
        },
        config
      );
    } catch (e) {
      setToast({
        type: toastType.PREVIOUS_UPLOAD_SAGA,
        severity: toastSeverity.ERROR,
        message: message.PREVIOUS_UPLOAD_SAGA,
        status: true,
      });
      previousUploadDeleteActions.getPreviousUploadFailed({
        errorCode: "",
        message: "",
        status: false,
      });
    }
  }
}

export const message = {
  CONTACT_ADMIN: "Something went wrong. Try again or contact admin",
  CRM_FORM_UPDATE: "Record updated successfully!",
  CRM_FORM_UPDATE_ERROR: "Error in saving crm record!",
  DELETE_CRM_RECORD: "Record deleted successfully!",
  NO_CONTENT: "WE ARE SORRY, NO CONTENT FOUND!",
  NO_DATA_FOUND: "No Data Found",
  FILTER_SELECTION: "Please make selection of the filter(s)",
  REPORT_NAME_ERROR: "Report name already exists",
  REQUIRED_FIELD: "This field is required",
  SUCCESS: "Request successfully executed",
  UN_AUTHORISED: "Request not authorized",
  DELETE_TAB_WARNING: "Are you sure you want to delete this tab?",
  DELETE_WARNING: "Are you sure you want to delete?",
  SELECT_TAB_MESSAGE: "Please select at least one tab to download excel",
  FILE_UPLOAD_SUCESSFUL: "File successfully Uploaded",
  FILE_FORMAT_NOT_SUPPORTED: "File format not supported",
  DELETE_SUCCESS: "File successfully deleted",
  NO_DATA: "No Data Available",
  EDGE_DELETE_SUCCESS: "Edge successfully deleted",
  VALID_VALUE: "Please enter valid value",
  RECORD_DELETE_SUCCESS: "Record successfully deleted",
  RECORD_DELETE_ERROR: "Record could not be deleted",
  MAXIMUM_LIMIT_REACHED: "Max Number limit reached",
  TRADE_APPROVE_SUCESSFUL: "The trade has been approved successfully",
  TRADE_REJECT_SUCCESS: "The trade has been Rejected",
  GIFT_APPROVE_SUCESSFUL: "The gift has been approved successfully",
  GIFT_REJECT_SUCCESS: "The gift has been Rejected",
  SOMETHING_WENT_WRONG: "Something went wrong",
  MAIL_SENT_SUCCESS: "Mail Sent Successfully",
  MULTIPLE_FILES_NOT_ALLOWED: "Multiple files are not allowed",
  REQUIRED: "Required",
  DELETE_ROW: "Are you sure you want to delete this row?",
  NO_DATA_AVAILABLE: "No data available.",
  NO_EDGE_FOUND: "No edge found!",
  DUPLICATE_RECORD_FOUND: "Duplicate Record found.",
  DATA_VALIDATION: "Data Validation:",
  NO_SEARCH_FOUND: "No Match Found.",
  DATA_NOT_FOUND: "No trail found.",
  NO_IMAGE_FOUND: "WE ARE SORRY, NO IMAGE FOUND!",
};
export const errorCode = {
  200: message.SUCCESS,
  401: message.UN_AUTHORISED,
  500: message.CONTACT_ADMIN,
};

/* eslint-disable */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DisplaySections from "../DisplaySection/DisplaySection";
import ViewFields from "../Fields/ViewFields";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";
import O_ChartDataEntry from "../../../Organisms/Common/ChartData/O_ChartDataEntry";
import O_DealStatus from "../../../Organisms/Common/DealStatus/O_DealStatus";
import O_Guidlines from "../../../Organisms/Common/DealStatus/O_Guidlines";
import { clearChartKeys } from "../../../Pages/Common/PageLayout/P_PageLayout.Helper"; //TODO- Move this function into global common file.
import O_PostCheckList from "../../../Organisms/Common/Checklist/O_PostChecklist";
import O_lendersApproached from "../../../Organisms/Common/LendersApproached/O_lendersApproached";
import O_activeFacility from "../../../Organisms/Common/ActiveFacility/O_activeFacility";
import { withStyles } from "@material-ui/core/styles";
import GuideLines from "../../../Organisms/Common/Guidelines/Guidelines";
import { itemTypes } from "./displayTabs.Helper";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2} pr={1.3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const DisplayTabs = React.memo((props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState();

  const handleChange = (event, newValue) => {
    clearChartKeys();
    setValue(newValue);
  };
  const [tabs, setTabs] = React.useState(
    props.tabs && props.tabs.length > 0 ? props.tabs : []
  );
  
  useEffect(() => {
    const key=props.CurrentCard ? props.CurrentCard.header.title : "_activetab"
    const activeValue=(localStorage.getItem("activetab"))
    const parseValue=activeValue && JSON.parse(activeValue)
    if (parseValue && value !== undefined) {
      localStorage.setItem('activetab',JSON.stringify({...parseValue,[key]:value}))
    }else if(value !== undefined){
      localStorage.setItem('activetab',JSON.stringify({[key]:value}))
    }
  }, [value,props.CurrentCard.header.title])


  useEffect(() => {
    const key=props.CurrentCard ? props.CurrentCard.header.title : "_activetab"
    const activeValue=localStorage.getItem("activetab")
    const parseValue=activeValue && JSON.parse(activeValue)
    if (parseValue) {
      if (parseValue[key]) {
        setValue(parseValue[key])
      } else {
        setValue(0)
      }
    }else{
      setValue(0)
    }
  }, [])

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
        >
          {tabs && tabs.length > 0
            ? tabs.map((item, index) => {
                return (
                  <CustomTab
                    key={index}
                    label={item.title}
                    {...a11yProps(index)}
                  />
                );
              })
            : ""}
        </Tabs>
      </AppBar>
      {tabs && tabs.length > 0
        ? tabs.map((item, index) => {
            return (
              <Box>
                {item &&
                item.content &&
                item.content.type &&
                item.content.type == itemTypes.DEAL_STATUS ? (
                  <O_DealStatus
                    initialStage={
                      item.content.initialStage ? item.content.initialStage : ""
                    }
                    content={item.content ? item.content : {}}
                    data={props.data}
                  />
                ) : (
                  ""
                )}
                <TabPanel key={index} value={value} index={index}>
                  {item && item.content && item.content.sections ? (
                    <DisplaySections sections={item.content.sections} />
                  ) : (
                    ""
                  )}
                  {item && item.content && item.content.fields ? (
                    <ViewFields
                      fields={item.content.fields}
                      CurrentCard={props.CurrentCard}
                    />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.CHART ? (
                    <M_ChartCard
                      chartFilter={props.chartFilter ? props.chartFilter : ""}
                      content={
                        item.content.content && item.content.content.chart
                          ? {
                              ...item.content.content.chart,
                              chartId: item.content.content.chart.id,
                              id: undefined,
                            }
                          : {}
                      }
                    />
                  ) : (
                    ""
                  )}

                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.GUIDELINES ? (
                    <GuideLines
                      content={
                        item.content.content && item.content.content.workFlowID
                          ? {
                              workFlowID: item.content.content.workFlowID,
                              fields: item.content.content.fields,
                            }
                          : {}
                      }
                    />
                  ) : (
                    ""
                  )}

                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.MAPS ? (
                    <M_MapCard content={item.content.content} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.RESULT_TEMPLATE ? (
                    <M_ResultCard content={item.content.content} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.CHART_ENTRY ? (
                    <O_ChartDataEntry chart={item.content.content.chart} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.DEAL_STATUS &&
                  props.data &&
                  props.data.currentStatus &&
                  props.data.currentStatus.toLowerCase() !== itemTypes.COMPLETION ? (
                    <O_Guidlines data={props.data} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.DEAL_STATUS &&
                  props.data &&
                  props.data.currentStatus &&
                  props.data.currentStatus.toLowerCase() === itemTypes.COMPLETION ? (
                    <O_PostCheckList
                      config={item.content.content ? item.content.content : {}}
                      data={props.data}
                    />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.ACTIVE_FACILITY ? (
                    <O_activeFacility data={props.data} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == itemTypes.LENDERS_APPROACHED ? (
                    <O_lendersApproached data={props.data} />
                  ) : (
                    ""
                  )}
                </TabPanel>
              </Box>
            );
          })
        : ""}
    </div>
  );
});
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}
export default connect(mapStateToProps, undefined)(DisplayTabs);

export const fieldType = {
  NUMBER: "number",
  OPTIONSET: "optionset",
  HTML: "html",
  MULTISELECT: "multiselect",
  LOOKUP: "lookup",
  RADIOS: "radios",
  SECTION: "section",
  BIT: "bit",
  HYPER_TEXT: "hypertext",
  TABLE: "table",
  DOCUMENT: "document",
  USERNAME: "username",
  FLOAT: "float",
  INTEGER: "integer",
  MULTIPLE: "multiple",
  PERCENTAGE: "percentage",
  TEXT: "text",
  STRING: "string",
  DATE: "date",
  FINANCIAL: "financial",
  BUTTON: "button",
  BOOLEAN: "boolean",
  OBJECT: "object",
};

import React, { useState } from "react";
import A_TextField from "../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { tradeApproval } from "./TradeApproval.helper";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  FromEpochFormat,
  customDateFormat,
} from "../../../../Utils/Helpers/dateUtils";
import ViewFieldAsLink from "../../Pages/Common/Fields/ViewFieldAsLink";
import { DocumentUpload } from "../../../../Utils/Helpers/documentUploadHelper";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import { message } from "../../../../Utils/config/messages";
import { TradeApproval } from "../../../../Utils/config/config";
import { getCRMContactInfo } from "../../../../Utils/Helpers/common";

const useStyles = makeStyles(() => ({
  inputcontrol: {
    width: "99%",
  },
  buttons: {
    paddingTop: "10px",
  },
  buttonsColors: {
    backgroundColor: "green",
    color: "white",
    "&:hover": { backgroundColor: "green", color: "white" },
  },
  buttonsColors1: {
    backgroundColor: "red",
    color: "white",
    "&:hover": { backgroundColor: "red", color: "white" },
  },
  Label: {
    fontWeight: "600",
    width: 1000,
    wordBreak: "break-word",
    "&.MuiTableCell-root": {
      padding: "8px",
    },
  },
  textLabel: {
    wordBreak: "break-word",
    width: 1000,
    "&.MuiTableCell-root": {
      padding: "8px",
    },
  },
  heading: {
    paddingTop: "20px",
    paddingBottom: "10px",
  },
}));

const TradeApprovalButtons = (props) => {
  var fieldData =
    props && props.currentPage && props.currentPage.data
      ? props.currentPage.data
      : "";
  let { crmContactUserId } = getCRMContactInfo();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );

  const [showAdmin, setShowAdmin] = React.useState(false);

  React.useEffect(() => {
    var currentuser = loggedInUserData ? loggedInUserData : "";
    props &&
      props &&
      props.complianceOfficer.map((mailId) => {
        if (
          mailId.toLowerCase() == currentuser.toLowerCase() &&
          mailId.toLowerCase() != fieldData.createdBy.toLowerCase() &&
          fieldData.createdBy.toLowerCase() != currentuser.toLowerCase()
        ) {
          setShowAdmin(true);
        }
      });
  }, []);
  let { pageUrl } = useParams();
  let content = DocumentUpload;

  let id =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data._partitionKey
      ? props.currentPage.data._partitionKey
      : "";

  let comments =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data.comments
      ? props.currentPage.data.comments
      : "";
  let label =
    props && props.content && props.content.edgeLabel
      ? props.content.edgeLabel
      : "";
  const [text, setText] = useState();
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
  const handleSnackbar = (type, pageUrl) => {
    if (pageUrl != TradeApproval.PAGEURL) {
      if (type === true) {
        setChangesSaved({
          status: true,
          type: "success",
          msg: message.TRADE_APPROVE_SUCESSFUL,
        });
      } else {
        setChangesSaved({
          status: true,
          type: "error",
          msg: message.TRADE_REJECT_SUCCESS,
        });
      }
    } else {
      if (type === true) {
        setChangesSaved({
          status: true,
          type: "success",
          msg: message.GIFT_APPROVE_SUCESSFUL,
        });
      } else {
        setChangesSaved({
          status: true,
          type: "error",
          msg: message.GIFT_REJECT_SUCCESS,
        });
      }
    }
    setTimeout(location.reload.bind(location), 1000);
  };

  const handleTradeApproval = (type) => {
    tradeApproval(text, type, id, userid, pageUrl, handleSnackbar, label);
  };
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const changeToDateFormat = (data) => {
    let date = FromEpochFormat(data);
    let dateFormat = customDateFormat(
      date,
      props.content && props.content.dateFormat
        ? props.content.dateFormat
        : "DD/MM/YYYY"
    );
    return dateFormat;
  };
  const handleLookup = (data) => {
    let lookupData = JSON.parse(data);
    return (
      <>
        {lookupData.map((item) => {
          return item && item.title ? (
            <a href={`${item.label}/${item.id}/`}>{item.title}</a>
          ) : (
            ""
          );
        })}
      </>
    );
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "success",
      msg: "",
    });
  };
  const classes = useStyles();
  return (
    <>
      {fieldData && fieldData.approvalDate ? (
        <div>
          {props.content && props.content.commentsHeadding ? (
            <div className={classes.heading}>
              <Typography variant="h6">Review Outcome</Typography>
            </div>
          ) : (
            ""
          )}

          <TableRow>
            <TableCell component="th" scope="row" className={classes.Label}>
              Reviewed By
            </TableCell>

            <TableCell className={classes.textLabel}>
              {fieldData && fieldData.approver
                ? handleLookup(fieldData.approver)
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.Label}>
              Reviewed Date
            </TableCell>

            <TableCell id={"field.htmlId"} className={classes.textLabel}>
              {fieldData && fieldData.approvalDate
                ? changeToDateFormat(fieldData.approvalDate)
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.Label}>
              Reviewed Outcome
            </TableCell>

            <TableCell id={"field.htmlId"} className={classes.textLabel}>
              {fieldData && fieldData.approvalStatus ? "Approved" : "Reject"}
            </TableCell>
          </TableRow>
        </div>
      ) : (
        <div>
          {showAdmin && (
            <>
              <div>
                {props.content && props.content.commentsHeadding ? (
                  <Typography variant="h6">Approval Comments</Typography>
                ) : (
                  ""
                )}
                <A_TextField
                  id="display Comments"
                  className={classes.inputcontrol}
                  onChange={(e) => handleChange(e)}
                  label="Add Comments"
                  type={""}
                  variant="standard"
                  defaultValue={comments}
                />
              </div>
              <div className={classes.buttons}>
                <A_Button
                  startIcon={<DoneIcon />}
                  disabled={false}
                  label="Approve"
                  onClick={() => {
                    handleTradeApproval(true);
                  }}
                  className={classes.buttonsColors}
                />
                <A_Button
                  startIcon={<CloseIcon />}
                  disabled={false}
                  label="Reject"
                  onClick={() => {
                    handleTradeApproval(false);
                  }}
                  className={classes.buttonsColors1}
                />
              </div>
            </>
          )}
        </div>
      )}
      {fieldData.approvalStatus &&
      props &&
      props.content &&
      props.content.isExecution ? (
        <div>
          <div className={classes.heading}>
            <Typography variant="h6"> Trade Execution</Typography>
          </div>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.Label}>
              Execution Date
            </TableCell>
            <TableCell className={classes.textLabel}>
              {fieldData &&
              fieldData.executionDate != undefined &&
              fieldData.executionDate != ""
                ? changeToDateFormat(fieldData.executionDate)
                : fieldData.executionDate}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.Label}>
              Execution Statement
            </TableCell>
            <TableCell className={classes.textLabel}>
              <ViewFieldAsLink
                FieldId={props.FieldName}
                content={content}
                files={props.files}
                data={props}
                id={props.id}
              />
            </TableCell>
          </TableRow>
        </div>
      ) : (
        ""
      )}
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        type={changesSaved.type}
        autoHideDuration={2000}
        vertical="bottom"
        horizontal="center"
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

TradeApprovalButtons.propTypes = {
  currentPage: PropTypes.object,
  FieldName: PropTypes.any,
  files: PropTypes.any,
  id: PropTypes.any,
  content: PropTypes.any,
  resetManagePage: PropTypes.any,
  complianceOfficer: PropTypes.any,
};
export default TradeApprovalButtons;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText } from "@material-ui/core";
import { useParams } from "react-router";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import { getFormattedDate } from "../../../../../../../Utils/Helpers/dateUtils";
import selectedIcon from "./Icon.helpers";
import * as previousUploadActions from "../../../../../../../store/actions/previousUploadActions";
import * as previousUploadDeleteActions from "../../../../../../../store/actions/previousUploadDeleteActions";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import M_ActionGroup from "../../../ActionGroup/M_ActionGroup";
import { sortFiles } from "../../../../../../Factory/Molecules/Common/Renderers/Views/PreviousTemplate/previousUpload.helper";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
  CustomCard: {
    maxHeight: "52vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  list: {
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer",
    },
  },
  avatar: {
    margin: "auto",
    paddingLeft: "10px",
  },
  heading: {
    marginTop: "10px",
  },
  ActionGroup: {
    paddingTop: "25px",
  },
  ListFlex: {
    display: "flex",
  },
}));

const PreviousUploads = (props) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { templateId, crmId, pageUrl } = useParams();

  useEffect(() => {
    if (props.previousUpload.data) {
      let sortElements = props.previousUpload.data;
      sortElements = sortFiles(sortElements, "fileDisplayName");
      setReports(sortElements);
      setIsLoading(false);
    }
  }, [props.previousUpload]);

  useEffect(() => {
    props.previousUpload.data = undefined;
    props.getPreviousUpload({
      crmId: crmId,
      pageUrl:
        props.content.fileName == undefined ? pageUrl : props.content.fileName,
      container: props.content && props.content.containerType,
      directory: props.content && props.content.directory,
    });
  }, [templateId]);

  const handleDataChange = () => {
    setTimeout(() => {
      props.getPreviousUpload({
        crmId: crmId,
        pageUrl:
          props.content.fileName == undefined
            ? pageUrl
            : props.content.fileName,
        container: props.content && props.content.containerType,
        directory: props.content && props.content.directory,
      });
      props.delfile();
    }, 1000);
  };

  const handelDelete = async (e) => {
    const docName = e;
    const fileOp = "delete";
    await props.getPreviousUploadDelete({
      documentName: docName,
      fileOperation: fileOp,
      containerType: props.content && props.content.containerType,
    });
  };

  const handleClick = async (e) => {
    //TODO - refactor it. Use saga for async behavior.
    const documentName = e.file;
    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: documentName,
      type:
        props.content && props.content.containerType
          ? props.content.containerType
          : "generic",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    axios
      .post(
        url,
        {
          method: "GET",
        },
        { responseType: "blob", headers: header }
      )
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", e.fileDisplayName);
        document.body.appendChild(link);
        link.click();
      });
  };
  var documentName = _.get(props, "content.name");

  return (
    <>
      {documentName ? (
        <div className={classes.heading}>
          <h5>{documentName} </h5>
        </div>
      ) : (
        ""
      )}

      <div className={classes.CustomCard}>
        {/* TODO - correct logic as per new implementation (saga) */}
        {isLoading ? (
          <>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </>
        ) : reports && reports.length > 0 ? (
          reports.map((item, index) => (
            <ListItem key={index} className={classes.list}>
              <Grid container spacing={2}>
                <Grid xs={11}>
                  <List
                    button
                    onClick={() => handleClick(item)}
                    className={classes.ListFlex}
                  >
                    <ListItemAvatar className={classes.avatar}>
                      <Avatar
                        src={selectedIcon(item.fileDisplayName)}
                        variant="square"
                      />
                    </ListItemAvatar>

                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={item.fileDisplayName}
                        secondary={
                          "Uploaded on " +
                          getFormattedDate(item.creationTime, "DD-MM-YYYY")
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                {props.currentPage &&
                props.currentPage.pageConfig &&
                props.currentPage.pageConfig.pageSecurity &&
                props.currentPage.pageConfig.pageSecurity.Full === true ? (
                  <Grid xs={1} className={classes.ActionGroup}>
                    <div>
                      <M_ActionGroup
                        delete={props.content.delete}
                        onDelete={() => handelDelete(item.file)}
                        handleDataChange={() => handleDataChange()}
                        modify={true}
                      />
                    </div>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </ListItem>
          ))
        ) : (
          ""
        )}
      </div>
    </>
  );
};
PreviousUploads.propTypes = {
  previousUpload: PropTypes.any,
  getPreviousUpload: PropTypes.any,
  getPreviousUploadDelete: PropTypes.any,
  delfile: PropTypes.func,
  currentPage: PropTypes.any,
  content: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    previousUpload: state.previousUpload,
    previousUploadDelete: state.previousUploadDelete,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, previousUploadActions, previousUploadDeleteActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousUploads);

import React from "react";
import PropTypes from "prop-types";
import A_Select from "../../../../../Atoms/Inputs/Selects/A_Select";
import { makeStyles } from "@material-ui/core/styles";
import A_EnhancedTable from "../../../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import { useState } from "react";
import A_SimpleDialog from "../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import Grid from "@material-ui/core/Grid";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import A_DatePicker from "../../../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getDropdownValue,
  getMetricDTValue,
} from "../../../../../../../store/actions/manualAction";
import { useParams } from "react-router";
import { convertEpochToDateString, customDateFormat } from "../../../../../../../Utils/Helpers/dateUtils";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../../../Utils/apis/apis";
import { toastSeverity } from "../../../../../../../Utils/config/toastConfig";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { message } from "../../../../../../../Utils/config/messages";
import { hasPageFilter } from "../Charts/M_ChartCard.Helper";

const useStyles = makeStyles(() => ({
  inputcontrol: {
    width: "15dvw",
  },
  contentContainer: {
    justifyContent: "flex-end",
  },
  customWidth: {
    width: "500px",
  },
  metricDropdown: {
    textAlign: "center",
    marginBottom: "20px",
  },
  metricDataTable: {
    width: "100%",
  },
  saveMetric: {
    textAlign: "right",
    marginRight: "-10px",
  },
  errMsg: {
    color: "red",
  },
  parentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));

const ManualUpdate = ({ content }) => {
  const { getMetricDataTableChartId, getMetricDropdownChartId, saveMetricSp, getMetricDropdownValue, getMetricDataTableKey, getMetricDropdownKey } =
    content;
  const classes = useStyles();
  const dispatch = useDispatch();
  let { crmId, pageUrl } = useParams();
  const errorMsg = message.REQUIRED;
  const deleteMsg = message.DELETE_ROW;
  let config = commonFunctions.getHttpConfig();
  const defaultAction = { metricDate: null, metricValue: null };
  const defaultValidation = { isDate: null, isValue: null };
  const defaultSelectedOption = { label: null, value: null };
  const defaultToast = { severity: "", message: "", toastStatus: false };
  let requestPayload = { crmId, pageUrl };
  // Modal state
  const [openAddModal, setOpenAddModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isMetricAdd, setIsMetricAdd] = useState(false);
  // Data state
  const [optionSet, setOptionSet] = useState();
  const [metricDT, setMetricDT] = useState();
  // Other state
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [actionRowIndex, setActionRowIndex] = useState(undefined);
  const [actionValue, setActionValue] = useState(defaultAction);
  const [validation, setValidation] = useState(defaultValidation);
  const [toast, setToast] = useState(defaultToast);

  const pageFilters =  useSelector(
    (state) =>
    state.manageToolbarSelections && state.manageToolbarSelections.filters 
    )
  const currentPage =  useSelector(
    (state) =>
    state.currentPage
    )
    
  const getPagelLevelFilter=()=>{
    if(pageFilters && hasPageFilter(currentPage)){
      const PageLevelFilter={}
      pageFilters.forEach((pageFilter) => {
        PageLevelFilter[pageFilter.filterName] = pageFilter.value;
      });
      return PageLevelFilter
    }
  }
  let utcDateTime = null;
  if (actionValue.metricDate) {
    const [day, month, year] = actionValue.metricDate.split("/").map(Number);
    utcDateTime = new Date(Date.UTC(year, month - 1, day));
  }

  const metricDTValues = useSelector(
    (state) =>
      state &&
      state.getDTMetricReducer &&
      state.getDTMetricReducer.stateUpdated &&
      state.getDTMetricReducer.data.series
  );

  const metricDropdownState = useSelector(
    (state) =>
      state &&
      state.manualUpdateReducer &&
      state.manualUpdateReducer.stateUpdated &&
      state.manualUpdateReducer.data.series
  );

  useEffect(() => {
    dispatch(getDropdownValue({ getMetricDropdownChartId, getMetricDropdownValue, getMetricDropdownKey, ...requestPayload }));
  }, []);

  useEffect(() => {
    metricDropdownState && setOptionSet(getOptions(metricDropdownState));
  }, [metricDropdownState]);

  useEffect(() => {
    setMetricDT(getDataTableFormat(metricDTValues ? metricDTValues : []));
  }, [metricDTValues]);

  useEffect(() => {
    const pageFilter=getPagelLevelFilter()
    dispatch(
      getMetricDTValue({
        getMetricDataTableChartId,
        getMetricDataTableKey,
        metricId: selectedOption.value ? selectedOption.value : 0,
        pageFilterId:pageFilter ? pageFilter :{},
        ...requestPayload,
      })
    );
  }, [pageFilters,selectedOption.value]);

  // Format option
  function getOptions(arr, label = "label", value = "value") {
    return (arr || []).map((list) => {
      let obj = {};
      obj[`${label}`] = list.data[0];
      obj.Id = list.name[0];
      obj[`${value}`] = list.name[0];
      return obj;
    });
  }

  // Format datatable option
  function getDataTableFormat(arr) {
    return (arr || []).map((list) => {
      let obj = {};
      obj.metricValue = list.data[0];
      obj.id = Math.random();
      obj.metricDate = customDateFormat(list.name[0], "DD/MM/YYYY");
      return obj;
    });
  }

  // Get valid object logic
  function updateObject(obj) {
    const updatedObj = { ...obj };
    for (const key in updatedObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (updatedObj.hasOwnProperty(key) && updatedObj[key] === null) {
        updatedObj[key] = true;
      }
    }
    return updatedObj;
  }

  // Datatable config
  let colmunConfiguration = {
    title: "",
    noDataText: message.NO_DATA_AVAILABLE,
    paginate: true,
    searchable: false,
    sortable: false,
    striped: true,
    addButton: true,
    dragable: false,
    action: {
      edit: true,
      delete: true,
    },
    columnConfig: {
      metricDate: {
        id: "metricDate",
        label: "Date",
        sorting: false,
        hide: false,
      },
      metricValue: {
        id: "metricValue",
        label: "Value",
        sorting: false,
        type: "number",
        hide: false,
        isCommaEnable: true,
      },
    },
  };

  // Handle date-picker
  const handleDatePicker = (selectedValue) => {
    if (selectedValue !== 0) {
      setValidation({ ...validation, isDate: false });
    } else {
      setValidation({ ...validation, isDate: true });
    }
    const newDate = convertEpochToDateString(selectedValue);
    let temp = { ...actionValue };
    temp.metricDate = newDate;
    setActionValue(temp);
  };

  // Handle value text
  const handleValueText = (e) => {
    if (e.target.value !== "") {
      setValidation({ ...validation, isValue: false });
    } else {
      setValidation({ ...validation, isValue: true });
    }
    let temp = { ...actionValue };
    temp.metricValue = e.target.value;
    setActionValue(temp);
  };

  // Delete Row Cancelled
  const handleDeleteRowCancelled = () => {
    setActionRowIndex(undefined);
    setDeletePopup(false);
  };

  // Trigger delete
  const handleDeleteButton = (e) => {
    let id = e.currentTarget.getAttribute("dataId");
    setActionRowIndex(id);
    setDeletePopup(true);
  };

  // Confirm delete
  const handleDeleteRowConfirmed = () => {
    if (actionRowIndex) {
      let currRows = [...metricDT];
      let newRows = currRows.filter((row) => row.id != actionRowIndex);
      setMetricDT(newRows);
      setDeletePopup(false);
      setActionRowIndex(undefined);
    }
  };

  // Trigger edit button
  const handleEditButton = (e) => {
    setIsMetricAdd(false);
    setOpenAddModal(true);
    let id = e.currentTarget.getAttribute("id");
    setActionRowIndex(id);
    let currRows = [...metricDT];
    let newRows = currRows.filter((row) => row.id == id);
    setActionValue(newRows[0]);
    setValidation({ isDate: false, isValue: false });
  };

  // Handle sumbit
  const saveMetricData = () => {
    if (validation.isDate == false && validation.isValue == false) {
      //add case
      if (isMetricAdd) {
        let temp = { ...actionValue };
        temp.id = Math.random();
        setMetricDT(metricDT ? [...metricDT, temp] : [temp]);
      } else {
        //edit case
        if (actionRowIndex) {
          let updatedItem = (metricDT || []).map((item) => {
            if (item.id == actionRowIndex) {
              return { ...actionValue, id: item.id };
            }
            return item;
          });
          setMetricDT(updatedItem);
        }
      }
      setOpenAddModal(false);
      setActionValue(defaultAction);
      setValidation(defaultValidation);
    } else {
      setValidation(updateObject(validation));
    }
  };

  // Handle sumbit cancel
  const handleSaveCancelled = () => {
    setOpenAddModal(false);
    setActionRowIndex(undefined);
    setActionValue(defaultAction);
    setValidation(defaultValidation);
  };

  // Save all metric
  const saveAllMetric = async () => {
    const pagelevelfilters=getPagelLevelFilter() ? getPagelLevelFilter() : {}
    let payload = [
      {
        id: "TEST",
        spName: saveMetricSp,
        parameters: {
          investmentid: crmId,
          metricid: selectedOption.value,
          ...pagelevelfilters,
          values: JSON.stringify(
            (metricDT || []).map((list) => {
              return { date: list.metricDate, value: list.metricValue };
            })
          ),
        },
      },
    ];
    try {
      const response = await httpPost(
        `/SqlData/executeStoredProcedure`,
        payload,
        {
          ...config,
        }
      );
      if (response) {
        setToast({
          severity: toastSeverity.SUCCESS,
          message: message.SUCCESS,
          toastStatus: true,
        });
      }
    } catch (err) {
      setToast({
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        toastStatus: true,
      });
    }
  };

  // Dropdown Change
  const dropdownChange = (e) => {
    setSelectedOption(e);
  };

  // Handle add button
  const handleAddButtonClickEvent = () => {
    setOpenAddModal(true);
    setIsMetricAdd(true);
  };

  const handleSnackbarClose = () => {
    setToast(defaultToast);
  };

  return (
    <div className={classes.parentBox}>
      <div className={classes.metricDropdown}>
        <A_Select
          className={classes.inputcontrol}
          variant="standard"
          label="Metric"
          native={true}
          options={optionSet}
          onChange={dropdownChange}
        />
      </div>
      {selectedOption && selectedOption.value && (
        <div className={classes.metricDataTable}>
          <A_EnhancedTable
            data={metricDT}
            configurations={colmunConfiguration}
            handleAddButtonClick={handleAddButtonClickEvent}
            handleEditButtonClick={handleEditButton}
            handleDeleteButtonClick={handleDeleteButton}
            isEditRightAlign={true}
          />
          {metricDT && metricDT.length > 0 && (
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <A_Button label="Save" color="default" onClick={saveAllMetric} />
            </div>
          )}
        </div>
      )}
      <A_SimpleDialog
        open={openAddModal}
        title={isMetricAdd ? "Add" : "Edit"}
        maxWidth="sm"
        fullWidth={true}
        showclosebutton={false}
        disableEscapeKeyDown={true}
        dialogContent={
          <div>
            <A_DatePicker
              className={classes.customWidth}
              label={"Date"}
              defaultValue={utcDateTime}
              onChange={handleDatePicker}
              format={"dd/MM/yyyy"}
              required={true}
            />
            {validation.isDate && (
              <span className={classes.errMsg}>{errorMsg}</span>
            )}

            <A_TextField
              label="Value"
              variant="standard"
              defaultValue={actionValue.metricValue}
              inputType="value"
              onChange={handleValueText}
              type="number"
              className={classes.customWidth}
              required={true}
            ></A_TextField>
            {validation.isValue && (
              <span className={classes.errMsg}>{errorMsg}</span>
            )}
          </div>
        }
        dialogActions={
          <Grid container className={`${classes.contentContainer} mb-3`}>
            <Grid item xxs={2}>
              <A_Button
                label="Cancel"
                color="default"
                onClick={handleSaveCancelled}
              />
            </Grid>
            <Grid item xs={2}>
              <A_Button label="Add" color="default" onClick={saveMetricData} />
            </Grid>
          </Grid>
        }
      ></A_SimpleDialog>
      <A_SimpleDialog
        id="2"
        open={deletePopup}
        onClose={handleDeleteRowCancelled}
        dialogContent={deleteMsg}
        dialogActions={
          <Grid container className={classes.contentContainer}>
            <Grid item xs={2}>
              <A_Button
                label="No"
                color="default"
                onClick={handleDeleteRowCancelled}
              />
            </Grid>
            <Grid item xs={2}>
              <A_Button
                label="Yes"
                color="secondary"
                onClick={handleDeleteRowConfirmed}
              />
            </Grid>
          </Grid>
        }
      />

      {toast && toast.toastStatus && (
        <A_Snackbar
          open={toast.toastStatus}
          message={toast.message}
          type={toast.severity}
          autoHideDuration={2000}
          vertical="bottom"
          horizontal="right"
          handleClose={handleSnackbarClose}
        />
      )}
    </div>
  );
};

ManualUpdate.propTypes = {
  content: PropTypes.any,
  getMetricDataTableChartId: PropTypes.string,
  getMetricDropdownChartId: PropTypes.string,
  saveMetricSp: PropTypes.string,
  getMetricDropdownValue: PropTypes.string,
  getMetricDataTableKey: PropTypes.string,
  getMetricDropdownKey: PropTypes.string,
};

export default ManualUpdate;

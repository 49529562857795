import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import CloseIcon from "@material-ui/icons/Close";
import { httpGet, httpPost } from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import { portfolioapproval } from "../Layouts/layout.Helper";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    margin: "2px",
    marginTop: "25px",
  },
  hidden: {
    display: "none",
  },
  shown: {
    display: "block",
  },
  approveButton: {
    backgroundColor: "#4CAF50",
    textTransform: "none",
    color: "white",
    fontWeight: "600",
    alignItems: "center",
    "&:hover": {
      background: "#3d8c40",
    },
  },
  rejectButton: {
    backgroundColor: "red",
    color: "white",
    fontWeight: "600",
    marginLeft: "10px",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      background: "#9d4343",
    },
  },
  add: {
    width: "100%",
  },
}));

function PortfolioApproval(props) {
  const { crmId, pageUrl } = useParams();
  const [waitLoad, setwaitLoad] = React.useState(true);
  const [showPrimaryapprover, setshowPrimaryapprover] = React.useState(true);
  const [comments, setcomments] = React.useState("");
  const [defaultFilterval, setdefaultFilterval] = React.useState("");
  const [filterVal, setfilterVal] = React.useState([]);
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  const classes = useStyles();
  const getChart = async () => {
    try {
      let config = commonFunctions.getHttpConfig();
      let apiPayload = {
        label: "contact",
        properties: {
          email: loggedInUserData,
        },
      };
      let filterPayload = {
        chartId: props.content.filterchartId,
        parameters: { investmentId: crmId },
      };
      const filterVals = await httpPost(
        `${process.env.API_URL}/ChartData/getchartdata`,
        filterPayload,
        config
      );
      const transformedData =
        filterVals &&
        filterVals.data &&
        filterVals.data.series &&
        filterVals.data.series.map((item) => {
          return {
            label: item.data[0],
            value: item.name[0],
          };
        });
      const defaultFiltervalues =
        filterVals &&
        filterVals.data &&
        filterVals.data.series &&
        filterVals.data.series.map((item) => {
          return item.name[0];
        })[0];
      setdefaultFilterval(defaultFiltervalues);
      setfilterVal(transformedData);
      const res = await httpPost(
        `${process.env.API_URL}/CRMData/getRecordByProperty`,
        apiPayload,
        config
      );
      if (res && res.length > 0 && res[0] && res[0].id) {
        let uri = `${process.env.API_URL}/CRMData/isValidApprover?investmentId=${crmId}&contactId=${res[0].id}`;
        const result = await httpGet(uri, { ...config });
        localStorage.setItem("contact_id", res[0].id);
        if (result === true && res.length > 0) {
          setwaitLoad(false);
          localStorage.setItem(portfolioapproval.PRIMARYAPPROVER, true);
        } else {
          setshowPrimaryapprover(false);
          localStorage.setItem(portfolioapproval.PRIMARYAPPROVER, false);
        }
        return result;
      }
    } catch (e) {
      setwaitLoad(false);
      console.error("Error occured in PortfolioApproval", e);
    }
  };
  React.useLayoutEffect(() => {}, []);
  React.useLayoutEffect(() => {
    getChart();
  }, []);
  const approveAndReject = (sub) => {
    let resUpdate = {};
    let updatePayload = [
      {
        id: crmId,
        spName:
          sub === true
            ? portfolioapproval.APPROVESTOREDPROCEDURE
            : portfolioapproval.REJECTSTOREDPROCEDURE,
        parameters: {
          source: defaultFilterval,
          username: localStorage.getItem("contact_id"),
          comments: comments,
        },
      },
    ];

    try {
      let config = commonFunctions.getHttpConfig();
      let urlforUpdate = `${process.env.API_URL}/SqlData/executeStoredProcedure`;
      resUpdate = httpPost(urlforUpdate, updatePayload, { ...config });
      resUpdate.then(props.getCurrentPage({ pageUrl, crmId }));
      return resUpdate;
    } catch (e) {
      return (resUpdate = { message: e.message });
    }
  };
  function handleChange(event) {
    setcomments(event.target.value);
  }
  function selectedFilter(e) {
    if (e.value.length !== 0) {
      setdefaultFilterval(e.value);
    }
  }
  return waitLoad === true ? (
    <div style={{ textAlign: "center" }}>
      {showPrimaryapprover ? (
        <CircularProgress
          style={{ width: "50px", height: "50px", marginTop: "15px" }}
        />
      ) : (
        <>
          {showPrimaryapprover == false &&
            portfolioapproval.NOTTHEDESIGNATEDAPPROVER}
        </>
      )}
    </div>
  ) : defaultFilterval && defaultFilterval != "" ? (
    <>
      <A_Select
        style={{
          marginRight: 0,
          marginBottom: 20,
        }}
        variant="outlined"
        fullWidth={true}
        inputProps={{ style: { textAlign: "center" } }}
        native={true}
        defaultValue={defaultFilterval}
        options={filterVal}
        onChange={selectedFilter}
      />
      <div className="" style={{ fontFamily: "Roboto" }}>
        <strong>
          <h4 style={{ color: "rgb(182 149 98)" }}>
            {portfolioapproval.DATAAPPROVAL}
          </h4>
        </strong>
        <div>{portfolioapproval.NEWDATA}</div>
        <div className="">
          <InputLabel>
            <div className="">
              <TextField
                id="standard-basic"
                label="My Comments:"
                onChange={handleChange}
                className={classes.add}
              ></TextField>
            </div>
          </InputLabel>
          <div className={classes.button}>
            <Button
              className={classes.approveButton}
              onClick={() => {
                approveAndReject(true);
              }}
            >
              <DoneIcon />
              {portfolioapproval.APPROVE}
            </Button>

            <Button
              className={classes.rejectButton}
              onClick={() => {
                approveAndReject(false);
              }}
            >
              {" "}
              <CloseIcon />
              {portfolioapproval.REJECT}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {localStorage.getItem(portfolioapproval.PRIMARYAPPROVER) === "true"
        ? portfolioapproval.NODATATOAPPROVE
        : showPrimaryapprover == false &&
          portfolioapproval.NOTTHEDESIGNATEDAPPROVER}
    </>
  );
}
PortfolioApproval.propTypes = {
  content: PropTypes,
  options: PropTypes.any,
  getCurrentPage: PropTypes.func,
};

export default PortfolioApproval;

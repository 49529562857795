import React from 'react';
import PropTypes from "prop-types";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: 700
  }
});

export default function IrReportTable({ tableData }) {
  const classes = useStyles();
  const rows = tableData;
  const columns = Object.keys(tableData[0])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let columnList = columns.map((column) => {
    return (
      <StyledTableCell
        align={"left"}
        key={column}
      >
        {column}
      </StyledTableCell>
    );
  });
  function generateTableCell(rowKey, rowData,key) {
    let alignMent = "left";
    switch(rowKey){
      case "BiteSizeMin" :
      case "BiteSizeMax" :
        alignMent = "center";
        break;
      default:
    }
    if(rowKey==="Investor"){
      return  <StyledTableCell key={`${rowKey}-${key}`}><div id="htmlText" dangerouslySetInnerHTML={{ __html: rowData[rowKey] }}></div></StyledTableCell>
    }
    return <StyledTableCell key={`${rowData[rowKey]}-${key}`} align={alignMent}>{rowData[rowKey]}</StyledTableCell>;
  }
  
  const generateRows = (row,key) => {
    let rowKeys = Object.keys(row);
    let rw = rowKeys.map((rowKey,index) => {
      return generateTableCell(rowKey, row,index);
    });
    return <StyledTableRow key={`row-${key}`}>{rw}</StyledTableRow>;
  };
 
  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columnList}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage)
              : rows
            ).map((row,index) => generateRows(row,index))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />
    </Paper>
  );
}

IrReportTable.propTypes = {
  tableData: PropTypes.any
};
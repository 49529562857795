import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { handleOptionSetValue } from "../../../../../../../../Utils/Helpers/optionSet.helper";
import { ListItemSecondaryAction } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f1f1f1",
    height: "calc(100vh - 64px)",
    marginTop: "64px",
    position: "fixed",
    width: "100%",
    zIndex: "99999",
  },
  searchBar: {
    flexDirection: "row",
  },
  searchTabList: {
    width: "100%",
    "& button.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  closeView: {
    padding: "10px",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  crmSubheader: {
    background: "rgb(34, 34, 34, 14%)",
    color: "#212529",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  crmListItem: {
    background: "#f1f1f1",
    cursor: "pointer",
    "&:hover": {
      background: "rgb(34, 34, 34, 10%)",
    },
  },
  searchTabPanel: {
    padding: "0",
    marginTop: "8px",
    overflow: "auto",
    maxHeight: "calc(100vh - 124px)",
  },
  crmHeader: {
    "& > div": {
      minWidth: "30px",
    },
    "& > span": {
      fontSize: "12px",
      "& > b": {
        fontSize: "14px",
        marginRight: "8px",
      },
    },
  },
  actionItem: {
    maxWidth: "30%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    gap: "0.2rem",
  },
  chips: {
    backgroundColor: "#263440",
    color: "white",
    fontSize: "0.6rem",
    padding: "0.2rem",
    borderRadius: "0.2rem",
    fontWeight: 600,
  },
}));

const M_FullPageView = (props) => {
  const classes = useStyles();
  const { allCrmResult, setIsFullPage, handleClick, searchKey } = props;
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} id="SearchFullView">
      <TabContext value={value}>
        <TabHeading
          allCrmResult={allCrmResult}
          handleChange={handleChange}
          setIsFullPage={setIsFullPage}
        />

        <TabBodyContent
          allCrmResult={allCrmResult}
          handleClick={handleClick}
          searchKey={searchKey}
        />
      </TabContext>
    </div>
  );
};
M_FullPageView.propTypes = {
  allCrmResult: PropTypes.array,
  setIsFullPage: PropTypes.bool,
  handleClick: PropTypes.func,
  searchKey: PropTypes.string,
};
export default M_FullPageView;

// TabHeading component
const TabHeading = (props) => {
  const classes = useStyles();
  const { handleChange, allCrmResult, setIsFullPage } = props;
  return (
    <>
      <AppBar position="static" color="default" className={classes.searchBar}>
        <TabList
          onChange={handleChange}
          aria-label="search..."
          className={classes.searchTabList}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="All" value="1" />

          {allCrmResult.map((list) => (
            <Tab
              key={list.crmRecordType}
              label={list.crmRecordType}
              value={list.crmRecordType}
            />
          ))}
        </TabList>
        <Paper
          elevation={0}
          className={classes.closeView}
          onClick={() => setIsFullPage(false)}
        >
          <HighlightOffIcon />
        </Paper>
      </AppBar>
    </>
  );
};
TabHeading.propTypes = {
  allCrmResult: PropTypes.array,
  handleChange: PropTypes.func,
  setIsFullPage: PropTypes.bool,
};

// TabBodyContent render body component
const TabBodyContent = (props) => {
  const classes = useStyles();
  const { allCrmResult, handleClick, searchKey } = props;
  return (
    <>
      <TabPanel value="1" className={classes.searchTabPanel}>
        <List subheader={<li />}>
          {allCrmResult.length > 0 ? (
            <>
              {allCrmResult.map((item) => (
                <li key={item.crmRecordType}>
                  <ul className={classes.ul}>
                    <ListSubheader
                      disableSticky
                      className={classes.crmSubheader}
                    >
                      {item.crmRecordType}
                    </ListSubheader>
                    {item.crmRecordDetails.map((list, index, dataSet) => {
                      const { id, email, title, link, chips } = list;
                      return [
                        <ListItem
                          key={id}
                          onClick={() => handleClick(list)}
                          className={classes.crmListItem}
                        >
                          <ListItemAvatar>
                            <Avatar alt={title} src={link} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={title}
                            secondary={handleOptionSetValue(email)}
                            style={{
                              maxWidth: chips.length > 0 ? "70%" : "100%",
                            }}
                            secondaryTypographyProps={{
                              style: { whiteSpace: "break-spaces" },
                            }}
                          />
                          {chips.length > 0 ? (
                            <ListItemSecondaryAction
                              className={classes.actionItem}
                            >
                              {chips.map((item) => {
                                if (item && item.trim() !== "") {
                                  return (
                                    <span className={classes.chips}>
                                      {item.toString()}
                                    </span>
                                  );
                                } else {
                                  <></>;
                                }
                              })}
                            </ListItemSecondaryAction>
                          ) : (
                            ""
                          )}
                        </ListItem>,
                        index < dataSet.length - 1 ? (
                          <hr style={{ margin: 0 }} />
                        ) : (
                          ""
                        ),
                      ];
                    })}
                  </ul>
                </li>
              ))}
            </>
          ) : (
            <ListItem className={classes.crmHeader}>
              <span>
                <b>{searchKey}</b>&nbsp;No results found
              </span>
            </ListItem>
          )}
        </List>
      </TabPanel>
      {allCrmResult.map((list) => (
        <TabPanel
          key={list.crmRecordType}
          value={list.crmRecordType}
          className={classes.searchTabPanel}
        >
          {list.crmRecordDetails.map((list, index, dataSet) => {
            return [
              <ListItem
                key={list.id}
                onClick={() => handleClick(list)}
                className={classes.crmListItem}
              >
                <ListItemAvatar>
                  <Avatar alt={list.title} src={list.link} />
                </ListItemAvatar>
                <ListItemText primary={list.title} />
              </ListItem>,
              index < dataSet.length - 1 ? <hr style={{ margin: 0 }} /> : "",
            ];
          })}
        </TabPanel>
      ))}
    </>
  );
};
TabBodyContent.propTypes = {
  allCrmResult: PropTypes.array,
  handleClick: PropTypes.func,
  searchKey: PropTypes.string,
};

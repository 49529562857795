export const contentTypes = {
  PORTFOLIOAPPROVAL: "portfolioapproval",
  CRMDATA: "crmdata",
  CHART: "chart",
  MAPS: "maps",
  NEWS: "news",
  RESULT_TEMPLATE: "resultTemplate",
  DOCUMENTS: "documents",
  PORTFOLIO_FILE_UPLOAD: "portfolioFileUpload",
  PREVIOUS_TEMPLATE: "previousTemplate",
  PREVIOUS_UPLOADS: "previousUploads",
  CHART_ENTRY: "chartEntry",
  TIMELINE: "timeline",
  GUIDELINES: "guidelines",
  MAPGOOGLE: "mapgoogle",
  IRC_DETAIL: "ircDetail",
  DEAL_ADMINISTRATION: "dealAdministration",
  BOOKING_SLOT: "bookingSlot",
  IC_VIEW: "icView",
  GENERIC_RESULT_TEMPLATE: "genericResultTemplate",
  CHECKLIST_MASTER: "checklistMaster",
  ACTIVE_FACILITY: "activeFacility",
  LENDERS_APPROACHED: "lendersApproached",
  MANAGED_BY: "managedBy",
  CHECKLIST: "checklist",
  MANAGE_INVOICES: "ManageInvoices",
  MEETING___PREFERENCE: "Meeting_Preference",
  IMAGE: "image",
  DEALSEARCHBAR: "dealsearchbar",
  BUDGET_APPROVAL: "budgetApproval",
  SUBMISSION_REVIEW: "submissionReview",
  SUBMISSION_APPROVAL: "submissionApproval",
  APPROVE_AND_REJECT: "approveAndReject",
  EXTERNAL_COMMITTEE: "externalCommittee",
  IMAGE_GALLERY: "imageGallery",
  ANNOUCMENT: "Annoucment",
  QUICK_LINKS: "quickLinks",
  IMAGE_CARD: "ImageCard",
  NEWS_PORTAL: "newsPortal",
  STATIC_PDF: "staticPDF",
  INVESTMENT_CASE: "investmentCase",
  MANUAL_UPDATE: "ManualUpdate",
  IR_REPORT:"irReport"
};

export const articleTypes = {
  APPROVE_AND_REJECT: "approveAndReject",
  SUBMISSION_REVIEW: "submissionReview",
  KPI_CALL_BOX: "KpiCallBox",
  KPI_SQL_BOX: "KpiSqlBox",
  DEALS_PIPE_LINE: "dealsPipeLine",
  PIPE_LINE_FILTER: "pipeLineFilter",
  REPORT_EDITOR: "reportEditor",
  EXCEL_EDITOR: "excelEditor",
  DEALSTATUSBAR: "dealstatusbar",
  ESG: "esg",
  ESGRESPONSE: "esgresponse",
  ESG_RESPONSE: "esgResponse",
  CONSENTREQUEST: "consentRequest",
};

export const deviceTypes = {
  MD: "md",
  XS: "xs",
};

export const externalTypes = {
  GENERIC: "generic",
  BUTTON: "buttons",
  TEXT: "text",
  PIPELINE_DATA: "pipelineData",
  IMAGE_DATA: "imageData",
  IRC: "irc",
};

export const portfolioapproval = {
  PRIMARYAPPROVER: "primaryApprover",
  DATAAPPROVAL: "Data Approval",
  NOPENDINGDATA: "There are no pending files which are actionable.",
  APPROVE: "Approve",
  REJECT: "Reject",
  NEWDATA:
    "The new data displayed here will not be visible to the organisation until it has been approved",
  APP: "APP",
  REJ: "REJ",
  APPROVESTOREDPROCEDURE: "sp_PE_Approve_Data",
  REJECTSTOREDPROCEDURE: "sp_PE_Reject_Data",
  SOURCEID: "sourceId",
  FILTERVALUE: "filterValue",
  NODATATOAPPROVE:"No records available to be approved",
  NOTTHEDESIGNATEDAPPROVER:"The current user is not the designated approver",
  UNDEFINED:"undefined"
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import IrReportTable from "./IrReportTable";
import IrReportGoogleMap from "./IrReportGoogleMap";
import { useSelector } from "react-redux";
import { getInvestmentListArray, getMailingListArray } from "./irReport.helper";
import { message } from "../../../../../Utils/config/messages";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import * as XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: "150px",
  },
}));

export default function IrReportResultTab(props) {
  const { content } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [investmentTableData, setInvestmentTableData] = React.useState([]);
  const [heatMapData, setHeatMapData] = React.useState([]);
  const [mailListData, setMailListData] = React.useState([]);
  const [investmentListWidth, setInvestmentListWidth] = React.useState([]);
  const [mailingListWidth, setMailingListWidth] = React.useState([]);
  const state = useSelector((state) => state.getIrReportReducer);

  const excelDownload = () => {
    let jsonData = [];
    let widthData = [];
    let fileName = "";
    investmentTableData;
    if (value == 0) {
      jsonData = JSON.parse(JSON.stringify(investmentTableData));
      // jsonData = Array.from(investmentTableData);
      widthData = investmentListWidth;
      fileName = "InvestmentList.xlsx";
      state.investmentList.forEach((inv, i) => {
        jsonData[i]["Investor"] = inv.properties.Name;
      });
      widthData["Investor"] = widthData["Investor"] - 60;
    } else {
      jsonData = mailListData;
      widthData = mailingListWidth;
      fileName = "MailingList.xlsx";
    }
    let columnLengthData = [];
    Object.keys(widthData).map((i) => {
      columnLengthData.push({ width: widthData[i] });
    });
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();

    worksheet["!cols"] = columnLengthData;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Write the workbook to a file

    XLSX.writeFile(workbook, fileName);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state && state.investmentList && state.investmentList.length > 0) {
      const { investorList, heatMap, investorListWidthData } =
        getInvestmentListArray(state.investmentList);
      let filtereDinvestorList = investorList.map((i) => {
        Object.keys(i).map((key) => {
          content.columns.indexOf(key) === -1 ? delete i[key] : "";
        });
        return i;
      });
      setInvestmentTableData(filtereDinvestorList);

      setHeatMapData(heatMap);
      setInvestmentListWidth(investorListWidthData);
    } else {
      setInvestmentTableData([]);
      setHeatMapData([]);
    }
    if (state && state.mailingList && state.mailingList.length > 0) {
      const { mailingListArray, mailingListWidthData } = getMailingListArray(
        state.mailingList
      );
      setMailListData(mailingListArray);
      setMailingListWidth(mailingListWidthData);
    } else {
      setMailListData([]);
    }
  }, [state]);

  return (
    <div className={classes.root}>
      <AppBar
        position="initial"
        color="default"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Tabs
          style={{ display: "flex", width: "min-content" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Investor Details" {...a11yProps(0)} />
          <Tab label="Mailing List" {...a11yProps(1)} />
          <Tab label="Heat Map" {...a11yProps(2)} />
        </Tabs>
        <Button
          color="inherit"
          style={{
            width: "min-content",
            display: value === 2 ? "None" : "initial",
          }}
          onClick={excelDownload}
        >
          <GetAppIcon style={{ color: "#377CA7" }} />
        </Button>
      </AppBar>
      {
        <>
          <TabPanel value={value} index={0}>
            {state && state.apiCall ? (
              state.InvListloading ? (
                <div className={classes.loader}>
                  <A_CircularIndeternment size={60} />
                </div>
              ) : investmentTableData && investmentTableData.length > 0 ? (
                <IrReportTable tableData={investmentTableData} />
              ) : (
                <Typography style={{ textAlign: "center" }}>
                  {message.NO_DATA_FOUND}
                </Typography>
              )
            ) : (
              <Typography style={{ textAlign: "center" }}>
                {message.FILTER_SELECTION}
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {state && state.apiCall ? (
              state.MailListloading ? (
                <div className={classes.loader}>
                  <A_CircularIndeternment size={60} />
                </div>
              ) : mailListData && mailListData.length > 0 ? (
                <IrReportTable tableData={mailListData} />
              ) : (
                <Typography style={{ textAlign: "center" }}>
                  {message.NO_DATA_FOUND}
                </Typography>
              )
            ) : (
              <Typography style={{ textAlign: "center" }}>
                {message.FILTER_SELECTION}
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {state && state.apiCall ? (
              heatMapData && heatMapData.length > 0 ? (
                <IrReportGoogleMap
                  mapData={heatMapData}
                  heatMapInfoWindow={content.heatMapInfoWindow}
                />
              ) : (
                <Typography style={{ textAlign: "center" }}>
                  {message.NO_DATA_FOUND}
                </Typography>
              )
            ) : (
              <Typography style={{ textAlign: "center" }}>
                {message.FILTER_SELECTION}
              </Typography>
            )}
          </TabPanel>
        </>
      }
    </div>
  );
}
IrReportResultTab.propTypes = {
  content: PropTypes.object,
};

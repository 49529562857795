import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import A_Button from "../../Inputs/Buttons/A_Button";
import M_FormRenderer from "../../../Molecules/Common/Renderers/M_FormRenderer";
import * as validators from "../../../../../Utils/Helpers/validations";
import { useParams } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import { message } from "../../../../../Utils/config/messages";
import { fieldType } from "../../../../../Utils/config/fieldType";
import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: "6px",
    minWidth: "185px",
  },
  AlertMobile: {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginBottom: "30px",
      flexDirection: "column",
      alignItems: "center",
      textAlignLast: "center",
    },
  },
  ButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "22px",
    marginRight: "15em",
  },
  rightbutton: {
    float: "right",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    padding: "0px 5px 10px 5px !important",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      height: "13vh",
    },
  },
  formsPanel: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  buttonsPanel: {
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      padding: "0px",
    },
  },
}));

function getSteps(steps) {
  let stepsLabels = steps.map((step) => {
    return step.label;
  });
  return stepsLabels;
}

const A_HorizontalStepper = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(props.activeStep);
  const [skipped, setSkipped] = useState(new Set());
  const [validationSummary, setValidationSummary] = useState(undefined);
  const [invalidDate, setInvalidDate] = useState(false);
  const [saveError, setError] = useState(false);
  const steps = getSteps(props.steps);
  var Editable = props.isEditable;
  let fields = props.fields;
  let optionSets = props.optionSets;
  const showStepper = steps && steps.length > 1;
  const isStepOptional = () => {};

  useEffect(() => {
    props.onloading();
  }, [props.formData]);

  const isValidDate = (inValidDate) => {
    setInvalidDate(inValidDate);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  async function handleDelete() {}
  const handleNext = () => {
    if (handleValidations() && !invalidDate) {
      let newSkipped = skipped;
      if (activeStep === steps.length - 1) {
        // setDisableBack(true);
        handleErrorState();
        handleSave();
      } else {
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.onActiveStepChanged((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.onActiveStepChanged((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const getStepContent = (index, steps) => {
    let stepElements = [];
    let elementsProps = [];
    let contentRaw = steps[index];
    if (contentRaw) {
      if ("content" in contentRaw) {
        stepElements = contentRaw.content;
      } else if ("sections" in contentRaw) {
        stepElements = contentRaw.sections;
      } else if ("fields" in contentRaw) {
        stepElements = contentRaw.fields;
      }
      elementsProps = stepElements.map((element) => {
        let elementProps = {};
        let fieldProperties = fields.filter((field) => {
          return element.fieldId === field.id;
        })[0];

        if (fieldProperties !== undefined) {
          elementProps = { ...fieldProperties, ...element };
        } else {
          elementProps = element;
        }
        if (
          elementProps &&
          elementProps.fieldType.toLowerCase() ===
            fieldType.DOCUMENT.toLowerCase()
        ) {
          props.fileUploadConfig ? props.fileUploadConfig(elementProps) : "";
        }
        return elementProps;
      });
    }

    elementsProps.push({
      fieldType: "permissions",
    });

    return elementsProps;
  };
  const validationFieldLevel = (id) => {
    let validations = {};
    let isValidated = true;
    let elements = getStepContent(activeStep, props.steps);
    let currElement = elements.find((item) => item.id === id);
    currElement.error = false;
    currElement.helperText = "";
    setValidationSummary(validations);
    return isValidated;
  };
  const integerFieldMaximumLength = (id) => {
    let validations = {};
    let isValidated = true;
    let elements = getStepContent(activeStep, props.steps);
    let currElement = elements.find((item) => item.id === id);
    currElement.error = false;
    currElement.helperText = message.MAXIMUM_LIMIT_REACHED;
    setValidationSummary(validations);
    return isValidated;
  };
  const handleValidations = () => {
    let validations = {};
    let isValidated = true;
    let elements = getStepContent(activeStep, props.steps);
    localStorage.removeItem("pasted");
    for (let i = 0; i < elements.length; i++) {
      let currElement = elements[i];
      let currElementValue =
        props.formData === undefined
          ? props.formData
          : props.formData[currElement.id];
      if (
        currElement.fieldType.toLowerCase() === fieldType.INTEGER.toLowerCase()
      ) {
        if (currElementValue === "") {
          props.formData[currElement.id] = undefined;
        }
      }
      if (
        currElementValue !== "" &&
        currElement.range &&
        currElement.range.min &&
        currElement.range.max
      ) {
        if (
          currElementValue < currElement.range.min ||
          currElementValue > currElement.range.max
        ) {
          isValidated = false;
          validations[currElement.id] = {
            error: true,
            msg: `Please Enter value between ${currElement.range.min} to ${currElement.range.max}`,
          };
        } else {
          currElement.error = false;
          currElement.helperText = "";
        }
      }
      if (currElement.defaultValue != undefined) {
        if (currElement.defaultValue != "") {
          props.formData[currElement.id] = currElement.defaultValue;
        }
      }

      //isRequired validations
      if (currElement.required) {
        if (
          currElement.fieldType.toLowerCase() === fieldType.DATE.toLowerCase()
        ) {
          if (currElementValue === 0) {
            isValidated = false;
            validations[currElement.id] = {
              error: true,
              msg: message.REQUIRED_FIELD,
            };
          }
        } else if (
          currElement.fieldType.toLowerCase() === fieldType.LOOKUP.toLowerCase()
        ) {
          if (
            currElementValue === "" ||
            currElementValue === "[]" ||
            (typeof currElementValue === "object" &&
              currElementValue.data.id === "")
          ) {
            isValidated = false;
            validations[currElement.id] = {
              error: true,
              msg: message.REQUIRED_FIELD,
            };
          }
        } else if (
          currElement.fieldType.toLowerCase() ===
          fieldType.DOCUMENT.toLowerCase()
        ) {
          if (
            props.formData.uploadSuccess != true &&
            !validators.isRequired(currElementValue)
          ) {
            isValidated = false;
            validations[currElement.id] = {
              error: true,
              msg: message.REQUIRED_FIELD,
            };
          } else {
            currElement.error = false;
            currElement.helperText = "";
          }
        } else if (
          currElement.fieldType.toLowerCase() === fieldType.BIT.toLowerCase()
        ) {
          if (!currElement.checked) {
            isValidated = false;
            validations[currElement.id] = {
              error: true,
              msg: message.REQUIRED_FIELD,
            };
          }
        } else if (
          !validators.isRequired(
            currElementValue === "<p><br></p>" ? "" : currElementValue
          )
        ) {
          isValidated = false;
          validations[currElement.id] = {
            error: true,
            msg: message.REQUIRED_FIELD,
          };
        } else {
          currElement.error = false;
          currElement.helperText = "";
        }
      }
      // isRequired Validations tabular field
      if (
        currElement.fieldType.toLowerCase() === fieldType.TABLE.toLowerCase() &&
        currElement.rows
      ) {
        for (let j = 0; j < currElement.rows.length; j++) {
          for (let k = 0; k < currElement.rows[j].fields.length; k++) {
            if (currElement.rows[j].fields[k].required === true) {
              currElement.rows[j].fields[k].helperText = [];
              props.formData &&
              props.formData[currElement.rows[j].fields[k].id] != null &&
              props.formData[currElement.rows[j].fields[k].id] != ""
                ? (currElement.rows[j].fields[k].helperText[0] = false)
                : (currElement.rows[j].fields[k].helperText = [
                    true,
                    message.REQUIRED_FIELD,
                  ]);

              props.formData &&
              props.formData[currElement.rows[j].fields[k].id] != null &&
              props.formData[currElement.rows[j].fields[k].id] != ""
                ? (isValidated = true)
                : (isValidated = false);
            }
          }
        }
      }
      //field validations i.e. regex validations

      if (
        validators.isRequired(currElement.validations) &&
        validators.isRequired(currElementValue)
      ) {
        if (
          !validators.isRegexValidated(
            currElementValue,
            currElement.validations
          )
        ) {
          isValidated = false;
          validations[currElement.id] = {
            error: true,
            msg: message.VALID_VALUE,
          };
        }
      }
    }

    setValidationSummary(validations);
    return isValidated;
  };
  const handleSave = () => {
    props.onSave();
  };
  const handleClick = () => {
    props.resetManagePage();
  };

  let errorMsg =
    props &&
    props.isError &&
    props.isError.response &&
    props.isError.response.data &&
    props.isError.response.data.Message
      ? props.isError.response.data.Message
      : "";

  const handleErrorState = () => {
    if (errorMsg === message.DUPLICATE_RECORD_FOUND) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const isDisable = saveError ? false : activeStep === 0;
  let { pageUrl } = useParams();
  return (
    <div className={classes.root}>
      {props.isError && (
        <Alert severity="error">
          {errorMsg !== message.DUPLICATE_RECORD_FOUND ? (
            <>
              <AlertTitle>{props.isError.name}</AlertTitle>
              <strong>Something went wrong</strong>
              <br />
              {props.isError.message}
            </>
          ) : (
            <>
              <AlertTitle>{message.DATA_VALIDATION}</AlertTitle>
              {errorMsg}
            </>
          )}
        </Alert>
      )}
      {props.formSavedResponse && (
        <div>
          <Alert
            severity="success"
            action={
              <A_Button
                label="Open record"
                className={classes.button}
                href={`/${
                  props.isEditable == true ? pageUrl : props.entity.pageUrl
                }/${
                  props.formSavedResponse && props.formSavedResponse.id
                    ? props.formSavedResponse.id
                    : ""
                }`}
                variant="outlined"
                color="primary"
                onClick={handleClick}
              ></A_Button>
            }
          >
            <AlertTitle>Success</AlertTitle>
            <strong>Record saved successfully</strong>
          </Alert>
        </div>
      )}

      {props.closeFormWarning && (
        <Alert
          severity="warning"
          className={classes.AlertMobile}
          action={
            <div className={classes.ButtonWrapper}>
              <A_Button
                label="Don't close form"
                onClick={props.onHandleWarningOkButton}
                className={classes.button}
                color="primary"
                variant="outlined"
              ></A_Button>
              <A_Button
                label="Close form anyway"
                color="secondary"
                onClick={props.onHandleWarningCloseButton}
                className={classes.button}
                variant="outlined"
              ></A_Button>
            </div>
          }
        >
          <AlertTitle>Warning</AlertTitle>
          <strong>
            Are you sure you want to close this form? You will lose progress so
            far!
          </strong>
        </Alert>
      )}

      {!props.formSavedResponse && activeStep < steps.length && (
        <div>
          {showStepper && (
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          <div className={classes.formsPanel}>
            <M_FormRenderer
              // securityRestriction={props.securityRestriction} // required for Temp level security permission
              crmData={props.crmData}
              isEditable={props.isEditable}
              entity={props.entity}
              getFormData={props.onFormDataChange}
              onFormEdit={props.onFormEdit}
              validationSummary={validationSummary}
              elements={getStepContent(activeStep, props.steps)}
              optionSets={optionSets}
              lastFormData={props.formData}
              activeStep={props.activeStep}
              security={props.security}
              isValidDate={isValidDate}
              validationFieldLevel={validationFieldLevel}
              integerFieldMaximumLength={integerFieldMaximumLength}
              managePage={props.managePage}
              fromAddEntity={props.fromAddEntity}
            />

            <div className={classes.buttonsPanel}>
              <A_Button
                label="Back"
                disabled={isDisable}
                onClick={handleBack}
                className={classes.button}
              ></A_Button>
              {isStepOptional(activeStep) && (
                <A_Button
                  label="Skip"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                ></A_Button>
              )}

              <A_Button
                label={activeStep === steps.length - 1 ? "Finish" : "Next"}
                variant="contained"
                disabled={props.isLoading}
                color="primary"
                onClick={handleNext}
                className={(classes.button, classes.rightbutton)}
              ></A_Button>
              {activeStep === steps.length - 1 &&
              Editable === true &&
              props.entity.templateId ==
                "40727291-1fae-41bf-9ce9-e88f022fd84f" ? (
                <A_Button
                  label="Delete"
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    marginRight: "10px",
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  className={(classes.button, classes.rightbutton)}
                ></A_Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
A_HorizontalStepper.propTypes = {
  entity: PropTypes,
  parameters: PropTypes,
  templateId: PropTypes,
  content: PropTypes.array,
  handleFilterChange: PropTypes.func,
  managePage: PropTypes,
  crmData: PropTypes,
  isEditable: PropTypes,
  onFormDataChange: PropTypes,
  onFormEdit: PropTypes,
  steps: PropTypes,
  formData: PropTypes,
  activeStep: PropTypes,
  security: PropTypes,
  formSavedResponse: PropTypes,
  onHandleWarningCloseButton: PropTypes,
  onHandleWarningOkButton: PropTypes,
  closeFormWarning: PropTypes,
  message: PropTypes,
  isError: PropTypes,
  name: PropTypes,
  fields: PropTypes,
  optionSets: PropTypes,
  resetManagePage: PropTypes,
  onSave: PropTypes,
  fileUploadConfig: PropTypes,
  onActiveStepChanged: PropTypes,
  isLoading: PropTypes.bool,
  fromAddEntity: PropTypes.bool,
  onloading: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(A_HorizontalStepper);

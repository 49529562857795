import PropTypes from "prop-types";
import React, { useEffect } from "react";
import PageContext from "../../../../../contexts/pageContext";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DisplaySectionHeader from "../../../Molecules/Common/DisplaysectionHeader/DisplaySectionHeader";
import {
  FromEpochFormat,
  customDateFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { httpPost } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import "./ViewFields.css";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import { AddEdge } from "../../../../../Utils/Helpers/crmDataApis/crmDataApis";
import { useSelector } from "react-redux";
import { TableHead, Typography } from "@material-ui/core";
import { DocumentUpload } from "../../../../../Utils/Helpers/documentUploadHelper";
import { getFileName } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import ViewFieldAsLink from "./ViewFieldAsLink";
import TradeApprovalButtons from "../../../Molecules/TradeApproveButtons/TradeApprovalButtons";
import {
  handleOptionSetValue,
  getIcon,
} from "../../../../../Utils/Helpers/optionSet.helper";
import { fieldType } from "./fileds.Helper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { genericListEnums } from "../../../Organisms/Common/GenericList/genericList.Helper";
import { message } from "../../../../../Utils/config/messages";
import CircularProgress from "@material-ui/core/CircularProgress";
const ViewFields = (props) => {
  const fields = props.fields ? props.fields : [];
  let { crmId, pageUrl } = useParams();
  const [dealTeamData, setdealTeamData] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [listOfFiles, setListOfFiles] = React.useState([]);
  let { crmContactUserId, crmContactUserEmail } = getCRMContactInfo();
  const [formData, setFormData] = React.useState({});
  const [contactSyncFormProperties, setContactSyncFormProperties] =
    React.useState({});
  const [validationSummary, setValidationSummary] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [buttonId, setButtonId] = React.useState("");
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  let config = commonFunctions.getHttpConfig();
  const email = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].properties &&
    state.getLoggedInUserReducer.data[0].properties.email
      ? state.getLoggedInUserReducer.data[0].properties.email
      : crmContactUserEmail
  );
  const useStyles = makeStyles(() => ({
    table: {
      minWidth: "auto",
    },
    finishButton: {
      marginTop: "2rem",
      textTransform: "none",
    },
    inputControl: {
      margin: 0,
    },
    Label: {
      fontWeight: "600",
      width: 1000,
      wordBreak: "break-word",
    },
    textLabel: {
      wordBreak: "break-word",
      width: 1000,
    },
    boldText: {
      fontWeight: "600",
    },
    multiSelect: {
      margin: "0px",
      padding: "0px",
    },
    addressBtn: {
      marginBottom: "4px",
      marginRight: "8px",
    },
    fieldPadding: { padding: "0.4rem" },
    FieldCss: { padding: "0.6rem", textAlign: "end" },
    stringcss: { padding: "0.4rem", textAlign: "end" },
    stringFieldCss: { padding: "0.4rem", textAlign: "left" },
    labelCss: { fontSize: "0.9rem", fontWeight: "500", textAlign: "left" },
    tablecell: { borderBottom: "none" },
    tableHeading: {
      marginTop: "20px",
      color: "#1976D2",
    },
  }));
  const getData = async () => {
    let data = await getFileName(crmId, DocumentUpload.containerType);
    setListOfFiles(data);
  };
  const classes = useStyles();
  useEffect(() => {
    props.syncEdgeLabel ? fetchMyAPI() : "";
    getData();
  }, []);

  async function fetchMyAPI() {
    let convertedFormData = {
      sourceVertexId: crmId,
      edgeLabel: props.syncEdgeLabel,
    };
    let getEdgeRes = await httpPost(
      `${process.env.API_URL}/CRMData/getEdge`,
      convertedFormData
    );

    for (var i = 0; i < getEdgeRes.length; i++) {
      var loggedinID = userid;
      var loggedEmail = email;
      if (crmId == loggedinID || loggedEmail == getEdgeRes[i].createdBy) {
        setdealTeamData(false);
        break;
      } else {
        setdealTeamData(true);
      }
    }
  }

  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const rowValue = (crmData, field) => {
    try {
      field.rows = field.rows.map((item) =>
        item.fields.map((item2) => getValue(crmData, item2))
      );
    } catch (e) {
      // TODO add error handling here
    }
    return field;
  };
  const getValue = (crmData, field) => {
    if (field.fieldType != fieldType.TEXT) {
      field["value"] = crmData[field.id];
    }
    return field;
  };
  const truncate = (num, type) => {
    let places =
      props &&
      props.CurrentCard &&
      props.CurrentCard.content &&
      props.CurrentCard.content.decimal
        ? props.CurrentCard.content.decimal
        : 2; // TODO for making it field specific  backend change is required. For now its card specific

    if (num && type == fieldType.FLOAT && places != "") {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    } else {
      return num;
    }
  };

  const GetTextField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        {field.htmlId ? (
          <TableCell
            id={field.htmlId}
            className={`${classes.textLabel} ${
              field.bold ? classes.boldText : ""
            }`}
          ></TableCell>
        ) : (
          <TableCell
            align={`${field.align || "left"}`}
            className={`${classes.textLabel} ${
              field.bold ? classes.boldText : ""
            }`}
          >
            {getFieldValue(field, crmData)}
          </TableCell>
        )}
      </TableRow>
    );
  };
  const GetCheckboxField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell
          align={`${field.align || "left"}`}
          className={classes.textLabel}
        >
          {getFieldValue(field, crmData)}
        </TableCell>
      </TableRow>
    );
  };

  const verifyAndGetUrlWithHttp = (field, crmData) => {
    let url = getFieldValue(field, crmData);

    if (url && url.length > 4) {
      url = url.substring(0, 4) === "http" ? url : "http://" + url;
    }

    return url;
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({});
    setLoading(false);
    setValidationSummary({ error: false, msg: "" });
  };
  const ButtonAction = async () => {
    setShowButton(true);
    var data = {
      sourceVertexId: props.loggedIn.data[0].id,
      destinationVertexId: crmId, //Seached user,company
      edgeLabel: props.syncEdgeLabel,
      destinationVertexLabel: "contact",
    };
    await AddEdge(data);
    setChangesSaved({
      status: true,
      type: "success",
      msg: "Synced",
    });
  };
  const ContactSyncAction = async (button, id = null) => {
    if (
      button &&
      button.contactSync &&
      button.contactSync.popUpForm &&
      button.contactSync.templateId
    ) {
      setOpen(true);
      setContactSyncFormProperties(button.contactSync);
    }
    let SelectedContact = [];
    var data = {};
    let validated = true;
    if (typeof button === fieldType.BOOLEAN) {
      validated = handleValidations();
    }
    if (
      typeof button === fieldType.BOOLEAN &&
      formData &&
      formData.contact &&
      formData.contact.data &&
      formData.contact.data.length > 0
    ) {
      formData.contact.data.map((item) => {
        var contactSelected = {
          id: item.id,
          email: item.title ? item.title : undefined,
        };
        SelectedContact.push(contactSelected);
      });
      data = {
        ParentCRMID: SelectedContact,
        recordID: crmId,
        isCompany: pageUrl === genericListEnums.COMPANY ? true : false,
      };
    } else {
      if (
        button &&
        typeof button === fieldType.OBJECT &&
        button.contactSync &&
        !button.contactSync.popUpForm
      ) {
        data = {
          ParentCRMID: [
            {
              id:
                props.loggedIn &&
                props.loggedIn.data[0] &&
                props.loggedIn.data[0].id
                  ? props.loggedIn.data[0].id
                  : "",
              email:
                props.loggedIn &&
                props.loggedIn.data &&
                props.loggedIn.data[0] &&
                props.loggedIn.data[0].properties &&
                props.loggedIn.data[0].properties.email
                  ? props.loggedIn.data[0].properties.email
                  : "",
            },
          ],
          recordID: crmId,
          isCompany: pageUrl === genericListEnums.COMPANY ? true : false,
        };
      }
    }
    var response = {};
    if (Object.keys(data).length > 0 && validated) {
      setOpen(false);
      setLoading(true);
      setButtonId(
        button && button.contactSync && button.contactSync.id
          ? button.contactSync.id
          : id
      );
      response = await httpPost(
        `${process.env.API_URL}/CRMData/AddContactSync`,
        data,
        config
      );
      setOpen(false);
      setButtonId("");
      setFormData(false);
      setLoading(false);
    }
    if (typeof response == fieldType.BOOLEAN) {
      if (response) {
        setChangesSaved({
          status: true,
          type: "success",
          msg: "Synced",
        });
      } else {
        setChangesSaved({
          status: true,
          type: "error",
          msg: "Something went wrong",
        });
      }
    }
  };
  const GetLinkField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell
          align={`${field.align || "left"}`}
          className={classes.textLabel}
        >
          {field && field.inNewTab ? (
            <a
              style={{ display: "table-cell" }}
              href={verifyAndGetUrlWithHttp(field, crmData)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          ) : (
            <a
              href={verifyAndGetUrlWithHttp(field, crmData)}
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          )}
        </TableCell>
      </TableRow>
    );
  };
  const GetHtmlField = (props) => {
    let { field, crmData } = props;
    return crmData[field.id] ? (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" colSpan={2}>
          <Grid
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "flex-start",
              whiteSpace: "unset",
              wordBreak: "break-word",
            }}
          >
            <div
              className={`${field.bold ? classes.boldText : ""}`}
              id="htmlText"
              style={{
                position: "relative",
                overflow: "auto",
                whiteSpace: "unset",
              }}
              dangerouslySetInnerHTML={{ __html: crmData[field.id] }}
            ></div>
          </Grid>
        </TableCell>
      </TableRow>
    ) : (
      ""
    );
  };
  //handelTableFormat
  const GetTableField = (props) => {
    let { field, crmData } = props;
    field = rowValue(crmData, field);
    return (
      <TableRow>
        <TableCell colSpan={2} className={classes.tablecell}>
          <Grid container>
            <Grid item sm={12}>
              <h5 className={classes.tableHeading}>{field.label}</h5>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {field.head.map((columnHead, index) => (
                      <TableCell
                        key={columnHead}
                        className={
                          index == 0 ? classes.fieldPadding : classes.FieldCss
                        }
                      >
                        {columnHead}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {field && field.rows && Array.isArray(field.rows)
                    ? field.rows.map((item) => (
                        <TableRow key={item}>
                          {field && field.rows && Array.isArray(field.rows)
                            ? item.map((item2) => {
                                let lookUpValue = "";
                                if (item2.fieldType == fieldType.LOOKUP) {
                                  let lookUpResult = {};
                                  try {
                                    lookUpResult = JSON.parse(
                                      typeof item2.value === fieldType.STRING
                                        ? item2.value
                                        : "{}"
                                    );
                                  } catch (e) {
                                    lookUpResult = {};
                                  }
                                  lookUpValue =
                                    lookUpResult &&
                                    lookUpResult[0] &&
                                    lookUpResult[0]["title"]
                                      ? lookUpResult[0]["title"]
                                      : "";
                                }
                                return (
                                  <TableCell
                                    key={item2}
                                    className={`${
                                      item2.fieldType == fieldType.STRING
                                        ? classes.stringFieldCss
                                        : classes.stringcss
                                    } ${item2.bold ? classes.boldText : ""}`}
                                    colSpan={
                                      item.length == 2 &&
                                      item2.fieldType != fieldType.TEXT &&
                                      2
                                    }
                                  >
                                    <>
                                      {item2.fieldType == fieldType.STRING ||
                                      item2.fieldType == fieldType.INTEGER ||
                                      item2.fieldType == fieldType.FLOAT
                                        ? item2.value
                                          ? item2.value != ""
                                            ? truncate(
                                                item2.value,
                                                item2.fieldType
                                              )
                                            : ""
                                          : ""
                                        : ""}
                                      {item2.fieldType == fieldType.OPTIONSET
                                        ? getFieldValue(item2, crmData)
                                        : ""}
                                      {item2.fieldType == fieldType.TEXT ? (
                                        <>
                                          <Typography
                                            className={classes.labelCss}
                                            varient="subtitle2"
                                          >
                                            {item2 && item2.label
                                              ? item2 && item2.label
                                              : item2 &&
                                                item2.name &&
                                                item2.name}
                                          </Typography>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item2.fieldType == fieldType.LOOKUP
                                        ? lookUpValue
                                        : ""}
                                    </>
                                  </TableCell>
                                );
                              })
                            : ""}
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };
  const GetDocumentField = (props) => {
    let content = DocumentUpload;
    let { field } = props;
    return (
      <>
        {field.fieldType.toLowerCase() === fieldType.DOCUMENT &&
        field.fieldAsLink === false ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              {field.label}
              <M_DropableFileUpload
                content={content}
                className={classes.textLabel}
              />
            </TableCell>
          </TableRow>
        ) : field.fieldType.toLowerCase() === fieldType.DOCUMENT &&
          field.fieldAsLink &&
          listOfFiles &&
          listOfFiles.length > 0 ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              // colSpan={2}
              className={classes.Label}
            >
              {field.label}
            </TableCell>
            <TableCell align={"left"}>
              <ViewFieldAsLink
                content={content}
                files={listOfFiles}
                data={props}
                id={crmId}
                FieldId={field.id}
              />
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };

  const GetButtonField = (props) => {
    let { field } = props;
    return (
      <>
        {dealTeamData ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              <A_Button
                size="small"
                color="primary"
                label={field.label}
                onClick={ButtonAction}
                disabled={showButton}
              />
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };
  const handleLookup = (selectedValue, id) => {
    let newFormData = { ...formData };
    selectedValue.data != ""
      ? (newFormData[id] = { ...selectedValue, fieldType: fieldType.LOOKUP })
      : (newFormData[id] = undefined);
    setFormData(newFormData);
  };
  const GetContactSync = (button) => {
    let loggedInUserId =
      props.loggedIn &&
      props.loggedIn.data &&
      props.loggedIn.data[0] &&
      props.loggedIn.data[0].id;
    return (
      <>
        {crmId !== loggedInUserId ? (
          <TableRow key={button.contactSync.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              <Box>
                <A_Button
                  size="small"
                  color="primary"
                  label={button.contactSync.label}
                  disabled={loading}
                  onClick={() => ContactSyncAction(button)}
                  className={classes.addressBtn}
                />
                {button.contactSync.id === buttonId && loading && (
                  <CircularProgress size={18} />
                )}
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };

  const GetContentFieldType = (props) => {
    let { field } = props;
    return (
      <>
        {field &&
        field.fieldType &&
        (field.fieldType == fieldType.DATE ||
          field.fieldType == fieldType.PERCENTAGE ||
          field.fieldType == fieldType.MULTIPLE ||
          field.fieldType == fieldType.LOOKUP ||
          field.fieldType == fieldType.OPTIONSET ||
          field.fieldType == fieldType.RADIOS ||
          field.fieldType == fieldType.FLOAT ||
          field.fieldType == fieldType.INTEGER ||
          field.fieldType == fieldType.MULTISELECT ||
          field.fieldType == fieldType.FINANCIAL ||
          field.fieldType == fieldType.TEXT) ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == fieldType.HYPER_TEXT ? (
          <GetLinkField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == fieldType.HTML ? (
          <GetHtmlField {...props} />
        ) : (
          ""
        )}

        {field && field.fieldType && field.fieldType == fieldType.TABLE ? (
          <GetTableField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === fieldType.STRING &&
        field.validations.includes("http") ? (
          <GetLinkField {...props} />
        ) : field &&
          field.fieldType &&
          field.fieldType.toLowerCase() === fieldType.STRING ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === fieldType.DOCUMENT ? (
          <GetDocumentField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == fieldType.BIT ? (
          <GetCheckboxField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == fieldType.SECTION ? (
          <TableCell component="th" scope="row" colSpan={2}>
            <DisplaySectionHeader {...props} label={field.label} />
          </TableCell>
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === fieldType.BUTTON ? (
          <GetButtonField {...props} />
        ) : (
          ""
        )}
      </>
    );
  };
  const getFieldValue = (field, crmData) => {
    let multipleLabel = "";
    let percentLabel = "";
    let val = "";
    if (field.fieldType == fieldType.BIT) {
      val = crmData[field.id] === true ? "Yes" : "No";

      return val;
    }
    if (field.fieldType == fieldType.OPTIONSET && !!crmData[field.id]) {
      let data = crmData[field.id];
      data = handleOptionSetValue(data, false);
      let optionSet =
        props.currentPageConfig &&
        props.currentPageConfig.optionSets &&
        props.currentPageConfig.optionSets
          ? props.currentPageConfig.optionSets.find(
              (item) => item.Id == field.optionSet
            )
          : "";

      let optionSetItem =
        optionSet && optionSet.options.find((item) => item.value == data);
      val = optionSetItem && optionSetItem.label ? optionSetItem.label : "";
      return val;
    }
    if (field.fieldType == fieldType.MULTIPLE) {
      multipleLabel = field.id;
    }

    if (field.fieldType == fieldType.PERCENTAGE) {
      percentLabel = field.id;
    }
    if (field.fieldType == fieldType.LOOKUP) {
      let data = crmData[field.id];
      let convertData = [];
      if (data != "" && data != undefined) {
        convertData = JSON.parse(data);
      }
      return (
        <>
          {convertData.map((item) => {
            return (
              <p className={classes.multiSelect} key={item}>
                {item && item.title ? (
                  field.inLink ? (
                    <a href={`${item.label}/${item.id}/`}>{item.title}</a>
                  ) : (
                    item.title
                  )
                ) : (
                  item.emailAddress && item.emailAddress.address
                )}
              </p>
            );
          })}
        </>
      );
    }

    //handelTableFormat
    if (field.fieldType == fieldType.MULTISELECT) {
      let data = crmData[field.id];
      let convertData = [];
      if (data != "" && data != undefined) {
        convertData = JSON.parse(data);
      }
      return (
        <>
          {convertData.map((item) => {
            return (
              <p className={classes.multiSelect} key={item}>
                {item}
              </p>
            );
          })}
        </>
      );
    }

    if (field.fieldType == fieldType.DATE) {
      if (crmData && crmData[field.id] !== undefined) {
        let dateValue = FromEpochFormat(crmData[field.id]);
        dateValue =
          typeof dateValue === "object" ? dateValue.toString() : dateValue;
        return customDateFormat(dateValue, field.formatType);
      }
    }

    if ("value" in field || crmData === undefined || crmData === null) {
      val = field.value;
    } else {
      if (percentLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "%";
        }
      } else if (multipleLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "x";
        }
      } else {
        val = crmData[field.id];
      }
    }
    if (field.fieldType == fieldType.FLOAT && val != "") {
      return truncate(val, field.fieldType);
    }
    //Handle finacial Fields
    if (field.fieldType == fieldType.FINANCIAL) {
      if (crmData && (crmData.currency || crmData.Currency)) {
        return getIcon(crmData, val);
      } else {
        return val;
      }
    }
    return val;
  };
  const handleValidations = () => {
    let validations = { Key: {} };

    setValidationSummary({ error: false, msg: "" });
    let isValidated = true;
    if (
      !formData.contact ||
      (Object.keys(formData).length === 0 && formData != [])
    ) {
      validations = {
        error: true,
        msg: message.REQUIRED_FIELD,
      };
      isValidated = false;
    }
    setValidationSummary(validations);
    return isValidated;
  };
  return (
    <PageContext.Consumer>
      {({ crmData }) => {
        return (
          <>
            <div>
              <Table
                // className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  {fields &&
                    fields.length > 0 &&
                    fields.map((field) => {
                      return (
                        <GetContentFieldType
                          field={field}
                          crmData={crmData}
                          key={field}
                        />
                      );
                    })}
                  {props &&
                    props.content &&
                    props.content.contactSyncButtons &&
                    props.content.contactSyncButtons.map((button) => {
                      return <GetContactSync contactSync={button} />;
                    })}
                </TableBody>
              </Table>
              <A_SimpleDialog
                open={open}
                title={
                  contactSyncFormProperties.formTitle
                    ? contactSyncFormProperties.formTitle
                    : "Add Contact to Other's Address Book"
                }
                maxWidth="sm"
                dialogContent={
                  <div>
                    <>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <A_Autocomplete
                            size="medium"
                            entity={{}}
                            required={true}
                            variant="standard"
                            className={classes.inputControl}
                            label={
                              contactSyncFormProperties.formFieldLabel
                                ? contactSyncFormProperties.formFieldLabel
                                : "Contact"
                            }
                            error={
                              validationSummary && validationSummary.error
                                ? validationSummary.error
                                : ""
                            }
                            helperText={
                              validationSummary && validationSummary.msg
                                ? validationSummary.msg
                                : null
                            }
                            filters={contactSyncFormProperties.filters}
                            onChange={handleLookup}
                            id={"contact"}
                            fullWidth={true}
                            resultTemplate={
                              contactSyncFormProperties.templateId
                            }
                            multiple={true}
                            destinationVertex={"contact"}
                          />
                        </Grid>
                      </Grid>
                    </>

                    <Box style={{ float: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.finishButton}
                        type="button"
                        onClick={() =>
                          ContactSyncAction(true, contactSyncFormProperties.id)
                        }
                      >
                        Finish{" "}
                      </Button>
                    </Box>
                  </div>
                }
                onClose={handleClose}
              />
              <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                type={changesSaved.type}
                autoHideDuration={2000}
                vertical="bottom"
                horizontal="center"
                handleClose={handleSnackbarClose}
              />
            </div>
            {props && props.content && props.content.approvalButtons ? (
              <TradeApprovalButtons
                {...props}
                files={listOfFiles}
                complianceOfficer={props.content.complianceOfficer}
                id={crmId}
                FieldName={props.content.fieldIdForApprovalButtons}
                callback={getData}
              />
            ) : (
              ""
            )}
          </>
        );
      }}
    </PageContext.Consumer>
  );
};
function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
    currentPageConfig: state.currentPage.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

ViewFields.defaultProps = {
  fields: [],
  content: {},
  crmData: {},
  field: {},
  syncEdgeLabel: "",
  loggedinID: "",
  loggedIn: {},
};
ViewFields.propTypes = {
  fields: PropTypes.array,
  content: PropTypes.object,
  crmData: PropTypes.object,
  field: PropTypes.object,
  getLoggedInUser: PropTypes.func,
  syncEdgeLabel: PropTypes.string,
  loggedinID: PropTypes.id,
  loggedIn: PropTypes.object,
  CurrentCard: PropTypes.any,
  currentPageConfig: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewFields);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Select from "./A_Select";
import { Box } from "@material-ui/core";
import {
  FromEpochFormat,
  epochToDateFormatHelper,
} from "../../../../../Utils/Helpers/dateUtils";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  filterPosition: {
    display: "inline",
    marginLeft: "1.2rem",
  },
}));

// eslint-disable-next-line react/display-name
const M_CardFilter = React.memo((props) => {
  const classes = useStyles();

  const handleChange = (event, id) => {
    localStorage.removeItem(id);
    if (event && event.date && event.year) {
      props.handleCardLevelFilterData(
        { id: id, value: event.value, date: event.date, year: event.year },
        props.chart.data
      );
    } else if (event && event.date && event.month) {
      props.handleCardLevelFilterData(
        {
          id: id,
          value: event.value,
          date: event.date,
          month: event.month,
        },
        props.chart.data
      );
    } else if (event && event.date && event.dateFormat) {
      props.handleCardLevelFilterData(
        {
          id: id,
          value: event.value,
          date: event.date,
          dateFormat: event.dateFormat,
        },
        props.chart.data
      );
    } else {
      props.handleCardLevelFilterData(
        { id: id, value: event.value },
        props.chart.data
      );
    }
  };

  const getDropDownOptions1 = (properties) => {
    let array = [];
    let keyValue = filterPropsData(props.chart.data);

    keyValue.map((item) => {
      Object.keys(item).forEach(function eachKey(key) {
        if (key === properties.id) {
          if (properties && properties.date && properties.year) {
            if (item[properties.id] !== null) {
              let dateEpoch = FromEpochFormat(parseInt(item[properties.id]));
              let date = new Date(dateEpoch);
              array.push(date.getFullYear());
            }
          } else if (properties && properties.date && properties.month) {
            if (item[properties.id] !== null) {
              let dateEpoch = FromEpochFormat(parseInt(item[properties.id]));
              let date = new Date(dateEpoch);

              array.push(date.toLocaleString("default", { month: "short" }));
            }
          } else if (properties && properties.date && properties.dateFormat) {
            if (item[properties.id] !== null) {
              let CustomDate = epochToDateFormatHelper(
                parseInt(item[properties.id]),
                properties.dateFormat
              );

              array.push(CustomDate);
            }
          } else {
            if (
              item[properties.id] !== null &&
              item[properties.id] !== "" &&
              item[properties.id] !== "null" &&
              item[properties.id] !== "[]"
            ) {
              array.push(item[properties.id]);
            }
          }
        }
      });
    });
    if (properties && properties.date && properties.dateFormat) {
      array = array.filter((value) => !Number.isNaN(value));
    }

    let variable = [...new Set(array)];
    let optionset = [];
    variable.map((item) => {
      if (properties && properties.year) {
        optionset.push({
          value: item,
          label: item,
          year: properties.year,
          date: true,
          id: properties.id,
        });
      } else if (properties && properties.month) {
        optionset.push({
          value: item,
          label: item,
          month: properties.month,
          date: true,
          id: properties.id,
        });
      } else if (properties && properties.dateFormat) {
        optionset.push({
          value: item,
          label: item,
          dateFormat: properties.dateFormat,
          date: true,
          id: properties.id,
        });
      } else {
        optionset.push({ value: item, label: item, id: properties.id });
      }
    });
    if (properties && properties.sorting) {
      optionset.sort((preValue, nextValue) =>
        preValue.label.toString().localeCompare(nextValue.label.toString())
      );
    }
    if (
      properties &&
      properties.defaultValue &&
      !localStorage.getItem(properties.id)
    ) {
      let object = {
        id: properties.id,
        value: optionset[0] ? optionset[0].label : "",
      };
      localStorage.setItem(properties.id, JSON.stringify(object));

      props.handleCardLevelFilterData(
        { id: properties.id, value: optionset[0] ? optionset[0].label : "" },
        props.chart.data
      );
    }
    return (
      <div className={classes.filterPosition}>
        <A_Select
          id={properties.id}
          variant="standard"
          defaultValue={
            localStorage.getItem(properties.id)
              ? JSON.parse(localStorage.getItem(properties.id)).value
              : ""
          }
          label={properties.label ? properties.label : ""}
          minWidth={properties.minWidth ? properties.minWidth : ""}
          native={true}
          options={optionset}
          onChange={handleChange}
        />
      </div>
    );
  };

  const filterPropsData = (data) => {
    let resArray = [];
    if (data && data.series && data.series.length > 0) {
      for (let index = 0; index < data.series.length; index++) {
        let object = {};
        for (let j = 0; j < data.series[index].data.length; j++) {
          if (data && data.dimensions && data.dimensions.length > 0) {
            let dimentionKey = data.dimensions[0];
            let dimentionValue = data.series[index].name[0];
            object[dimentionKey] = dimentionValue;
          }

          let keyName =
            data.categories && data.categories.length > 0 && data.categories[j]
              ? data.categories[j]
              : "_";
          let keyValue = data.series[index].data[j];
          object[keyName] = keyValue;
        }
        resArray.push(object);
      }
    }
    return resArray;
  };

  return (
    <Box style={{ display: "table", margin: "10 auto" }}>
      {props.chartFilter.map((option) => getDropDownOptions1(option))}
    </Box>
  );
});
M_CardFilter.propTypes = {
  chart: PropTypes,
  chartFilter: PropTypes,
  handleCardLevelFilterData: PropTypes.func,
};

export default M_CardFilter;

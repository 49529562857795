/* eslint-disable */
import React, { useState } from "react";
import LoadingMessage from "./LoadingMessage/LoadingMessage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../utils/oidc/userManager";
import * as httpCall from "../../../Utils/apis/apis";
import defaultMinervaTheme from "../../Themes/defaultMinervaTheme";
import { useHistory } from "react-router-dom";
import * as loggedInUserIdActions from "../../../store/actions/loggedInUserIdActions";
import PropTypes from "prop-types";
import RedirectingMessage from "../RedirectingMessage/RedirectingMessage";
const CallbackPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const getTheme = async (user) => {
    setIsLoading(true);
    //LoggedIn User Data
    await props.getLoggedInUser();
    let config = {
      headers: {
        Authorization: "Bearer " + null,
      }, 
    };
    if (user) {
      config = {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      };
    }
    let redirectUrl = null;

    //user validation for subdomain
    let apiUrl = `/common/validsubdomainuser`;
    const result = await httpCall.httpGet(apiUrl, config);
    if (result) {
      if (user.state.pathname != "") {
        redirectUrl = user.state.pathname;
      } else {
        apiUrl = "/common/getDefaultRedirectURLForUser";
        redirectUrl = await httpCall.httpGet(apiUrl, config);
      }
    } else {
      redirectUrl = "/securelogout";
    }

    let email =
      props.state &&
      props.state.oidc &&
      props.state.oidc.user &&
      props.state.oidc.user.profile &&
      props.state.oidc.user.profile.email;
    if (email) {
      let apiPayload = {
        label: "contact",
        properties: {
          email: email,
        },
      };
      const res = await httpCall.httpPost(
        `${process.env.API_URL}/CRMData/getRecordByProperty`,
        apiPayload,
        config
      );
      let contact_crm = "";
      if (res && res.length > 0 && res[0] && res[0].id) {
        contact_crm = res[0];
      }
     if (contact_crm) {
        localStorage.setItem("contact_crm", JSON.stringify(contact_crm));
      }
    }
    
    if(user){
      apiUrl ='/CRMData/globalSearchPermission';
     const response =  await httpCall.httpGet(apiUrl, config);

      if (!response.message) {
      localStorage.setItem("showSeach", response);
    } else {
        localStorage.setItem("showSeach", "disable");
      }
    }
  
    apiUrl = "/ColourScheme";
    let colourSchemeResult = await httpCall.httpGet(apiUrl, config);
    if ("message" in colourSchemeResult) {
      const defaultSerializedTheme = JSON.stringify(defaultMinervaTheme);
      localStorage.setItem("minervaTheme", defaultSerializedTheme);
    } else {
      const serializedTheme = JSON.stringify(colourSchemeResult);
      localStorage.setItem("minervaTheme", serializedTheme);
    }
    setIsLoading(false);
    history.push(redirectUrl ? redirectUrl : "/dashboard");
    localStorage.setItem(
      "UserDefaultURL",
      redirectUrl ? redirectUrl : "/dashboard"
    );
  };

  const successCallback = (user) => {
    getTheme(user);
  };

  const errorCallback =() => {
    history.push("/")
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <div>
        {isLoading && <LoadingMessage />}
        {!isLoading && <LoadingMessage />}
      </div>
    </CallbackComponent>
  );
};
function mapStateToProps(state) {
  return { state, loggedIn: state.getLoggedInUserReducer };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}
CallbackPage.propTypes = {
  getLoggedInUser: PropTypes.func,
  loggedinUser: PropTypes.func,
  loggedIn: PropTypes.array,
  currentPageId: PropTypes.string,
  content: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import GetAppIcon from "@material-ui/icons/GetApp";

const ChangeLogTitle = (props) => {
  const { numSelected, handleSearchChange, excelDownload, classes } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Record Audit Trail
      </Typography>
      <A_TextField
        onChange={handleSearchChange}
        label=""
        variant="outlined"
        adornment={{ position: "start", value: <SearchIcon /> }}
      />
      <Button
        color="inherit"
        style={{
          width: "min-content",
          paddingTop: "12px",
        }}
        onClick={excelDownload}
      >
        <GetAppIcon style={{ color: "#377CA7" }} />
      </Button>
    </Toolbar>
  );
};

ChangeLogTitle.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearchChange: PropTypes.func,
  excelDownload: PropTypes.func,
  classes: PropTypes.any,
};

export default ChangeLogTitle;

export const rgbtoHex = (value) => {
  let htmlString = value;

  let regex = /(?:rgba?\(([\d., ]+)\)|hsla?\(([\d., ]+)\)|rgb\(([\d., ]+)\)|hsl\(([\d., ]+)\))/gi;

  // Function to convert RGB/RGBA to hex color
  function rgbConverter(r, g, b, a) {
    let alpha =
      typeof a !== "undefined"&& isNaN(a)==false
        ? Math.round(a * 255)
            .toString(16)
            .padStart(2, "0")
        : "";
    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}${alpha}`;
  }

  // Function to convert HSL/HSLA to hex color
  function hslConverter(h, s, l, a) {
    let alpha =
      typeof a !== "undefined"
        ? Math.round(a * 255)
            .toString(16)
            .padStart(2, "0")
        : "";
    let rgbColor = hslToRgb(h, s, l);
    return `#${rgbColor.r.toString(16).padStart(2, "0")}${rgbColor.g
      .toString(16)
      .padStart(2, "0")}${rgbColor.b.toString(16).padStart(2, "0")}${alpha}`;
  }

  // Helper function to convert HSL to RGB
  function hslToRgb(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      let hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
      g = Math.round(hue2rgb(p, q, h) * 255);
      b = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);
    }

    return { r, g, b };
  }

  // Replace color codes with hex color in the HTML string
  htmlString = htmlString.replace(regex, (match, colorValues) => {
    let values = colorValues
      .split(",")
      .map((value) => parseFloat(value.trim()));
    let colorType = match.split("(")[0];
    let hexColor;

    switch (colorType) {
      case "rgb":
      case "rgba":
        hexColor = rgbConverter(...values);
        break;
      case "hsl":
      case "hsla":
        hexColor = hslConverter(...values);
        break;
      default:
        hexColor = match;
    }

    return hexColor;
  });

  return htmlString;
};

export const styleReplacer = (htmlString) => {

  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Find all elements with inline styles
  const elementsWithStyles = tempElement.querySelectorAll('[style]');

  // Loop through each element and check for 'position: absolute'
  elementsWithStyles.forEach((element) => {
    const style = element.getAttribute('style');
    if (style.includes('position: absolute')) {
      // Replace 'position: absolute' with 'position: relative'
      const updatedStyle = style.replace('position: absolute', 'position: relative');
      element.setAttribute('style', updatedStyle);
    }
  });

  // Return the modified HTML string
  return tempElement.innerHTML;


}
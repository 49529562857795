import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import IrReportForm from './IrReportForm'
import IrReportResultTab from './IrReportResultTab'


function IRReport(props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3} >
        <Paper style={{ padding: "10px" }}>
          <IrReportForm {...props}/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper style={{ padding: "5px", height:"100%" }}><IrReportResultTab {...props}/></Paper>
      </Grid>
    </Grid>
  )
}

export default IRReport
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import M_TemplatePagesHeader from "../../../Molecules/Common/PageHeaders/TemplatePageHeader/M_TemplatePagesHeader";
import PageContext from "../../../../../contexts/pageContext";
import O_LayoutMenu from "../../../Organisms/Common/LayoutMenu/O_LayoutMenu";
import Layout from "../Layouts/layout";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import PageNotFound from "../../../../../Routes/PageNotFound";
import { useHistory } from "react-router-dom";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import * as addToolbarFilterActions from "../../../../../store/actions/manageToolbarSelectionsActions";
import { SortedLayOuts } from "../../../../Factory/Molecules/Common/Navigations/LayoutMenu/LayoutMenu";
import { handleShowHide, templateToShow } from "./pageLayoutfunctions";
import {
  AddFilterToChartCard,
  clearChartKeys,
  pageTypes,
} from "./P_PageLayout.Helper";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import * as toastActions from "../../../../../store/actions/toastActions";
import PropTypes from "prop-types";
import { toastType } from "../../../../../Utils/config/toastConfig";
import { pageConfig } from "../../../../../Utils/config/config";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LoadingMessage from "../../../../Utils/Oidc/LoadingMessage/LoadingMessage";
import LoadingLayout from "../../../../Utils/Oidc/LoadingMessage/SkeletonLoading";

const useStyles = makeStyles((theme) => ({
  ToggleButton: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "2",
      position: "fixed",
      color: "white",
      marginTop: "40vh",
      fontSize: "40px",
      fontWeight: "bolder",
      marginLeft: "177vw",
      backgroundColor: "rgb(38 52 64 / 28%)",
      // backgroundColor:"transparent",
      borderRadius: "50px",
      transform: "rotate(180deg)",
      transition: "0.2s",
    },
  },
  ToggleButtontwo: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "2",
      position: "fixed",
      color: "white",
      marginTop: "43vh",
      marginLeft: "6.5vw",
      fontSize: "40px",
      fontWeight: "bolder",
      backgroundColor: "rgb(38 52 64 / 28%)",
      borderRadius: "50px",
      transition: "0.2s",
    },
  },
}));

const P_PageLayout = (props) => {
  let { pageUrl, crmId, templateId } = useParams();
  const [currentPage, setCurrentPage] = useState(undefined);
  let [layout, setLayout] = useState([]);
  let [viewOnlyLayouts, setViewOnlyLayouts] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [manualRefresh, setManualRefresh] = useState("");
  const [currentPageId, setCurrentPageId] = useState("");
  const [crmDataForShow, setCrmDataForShow] = useState([]);
  const layoutselection = useRef(null);
  const [apiError, setApiError] = useState(false);
  const [isExcelTemplateDownload, setIsExcelTemplateDownload] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  window.onpopstate = function () {
    window.location.reload();
  };

  const resetToast = () => {
    setToastStatus(false);
    props.setToast({
      type: "",
      severity: "",
      message: "",
      status: false,
    });
  };
  useEffect(() => {
    props.resetToolbarFilters();
    props.setTabs(null);
    props.currentPage.isLoading = true;
    props.getCurrentPage({ pageUrl, crmId });
    clearChartKeys();
    resetToast();
  }, []);

  const handleSnackbarClose = () => {
    setToastStatus(false);
  };

  useEffect(() => {
    if (props.toast && props.toast.message && props.toast.message !== "") {
      setToastStatus(true);
    }
    //TODO - edge logic to be excluded from page logic
    //TODO -Changing deal team will not impact rest of the component, that should be loaded
    //asynchrounously
    //TODO -Current implementation is just a patch.
    //TODO - props.toast.type === toastType.DELETE_EDGE this is deal team specific, which should not call complete page stuff only getEdge api
    if (
      props.toast.type === toastType.CRM_FORM_UPDATE ||
      props.toast.type === toastType.DELETE_EDGE ||
      props.toast.type === toastType.DELETE_RECORD
    ) {
      if (
        layout &&
        layout.templateJson &&
        layout.templateJson.cards[0] &&
        layout.templateJson.cards[0].content &&
        layout.templateJson.cards[0].content.content &&
        layout.templateJson.cards[0].content.content.content &&
        layout.templateJson.cards[0].content.content.content.type ===
          pageTypes.LENDERS_APPROACHED
      ) {
        setManualRefresh(parseInt(Math.random() * 1000000000, 10));
      } else {
        setTimeout(() => {
          props.getCurrentPage({ pageUrl, crmId });
        }, 500);
      }
    }
  }, [props.toast && props.toast.type]);

  // Current page management
  useEffect(() => {
    //set Active layout
    if (props.currentPage) {
      resetToast();
      if (
        props.currentPage.templates &&
        props.currentPage.templates.length > 0
      ) {
        setApiError(false);
        let vwOnlyLayouts = templateToShow(props.currentPage);
        vwOnlyLayouts.reverse();
        vwOnlyLayouts = SortedLayOuts(vwOnlyLayouts);
        var currTemplate =
          templateId == undefined
            ? vwOnlyLayouts[0]
            : vwOnlyLayouts.filter((layOut) => {
                return layOut.id == templateId;
              })[0]
            ? vwOnlyLayouts.filter((layOut) => {
                return layOut.id == templateId;
              })[0]
            : vwOnlyLayouts[0];
        if (currTemplate) {
          let currTemplateJson =
            typeof currTemplate.templateJson === pageTypes.STRING &&
            currTemplate.templateJson.length > 0
              ? JSON.parse(currTemplate.templateJson)
              : currTemplate.templateJson;
          currTemplate.templateJson = currTemplateJson;
          if (props.currentPage && props.currentPage.isLoading === undefined) {
            history.push("/" + pageUrl + "/" + crmId + "/" + currTemplate.id);
          } else if (
            templateId == undefined ||
            templateId !== currTemplate.id
          ) {
            if (props.currentPage.isLoading) {
              return;
            }
            history.push("/" + pageUrl + "/" + crmId + "/" + currTemplate.id);
          }
          setLayout(AddFilterToChartCard(currTemplate, props.currentPage));
          setPageSubtitle(currTemplate.templateJson.title);
          setCurrentPageId(currTemplate.id);
          setViewOnlyLayouts(vwOnlyLayouts);
        } else {
          setLayout(null);
          setApiError(true);
        }
      }
      //set page title
      const getTitle = (headerTitle) => {
        try {
          var titleHeader = JSON.parse(headerTitle);
          return titleHeader[0].title;
        } catch (e) {
          return headerTitle;
        }
      };
      if (
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.type === pageTypes.CRM //TODO - Change text comparision with id comparision
      ) {
        let fields = props.currentPage.pageConfig.fields;
        let titleFieldArry = fields.filter((field) => {
          return field.isPageTitle;
        });

        let concatTitle = "";
        for (let count = 0; count < titleFieldArry.length; count++) {
          if (
            props.currentPage.data &&
            props.currentPage.data[titleFieldArry[count].name] !== undefined
          ) {
            concatTitle = concatTitle.concat(
              getTitle(props.currentPage.data[titleFieldArry[count].name]) + " "
            );
          }
        }
        setPageTitle(concatTitle);
        setCrmDataForShow(props.currentPage.data);
      } else if (
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.type === pageConfig.NonCRM
      ) {
        setPageTitle(props.currentPage.pageConfig.name);
      }
    } else {
      setLayout([]);
    }
    setCurrentPage(props.currentPage);
    return () => {
      setCurrentPage(null);
    };
  }, [props.currentPage]);

  useEffect(() => {
    if (
      !props.currentPage.getCurrentPageSucceeded &&
      !props.currentPage.isLoading
    ) {
      setApiError(true);
    }
  }, [props.currentPage.getCurrentPageSucceeded, props.currentPage.isLoading]);

  useEffect(() => {
    props.getCurrentPage({ pageUrl, crmId });
  }, [pageUrl, crmId]);

  let newPageConfig = props.currentPage && props.currentPage.pageConfig;
  if (newPageConfig) {
    newPageConfig.subtitle = pageTypes.SUBTITLE;
  }

  function handleLayoutMenu(val) {
    setInitialSideBarClass("page-sidebar");
    setInitialBooleanValue(false);
    if (val !== undefined) {
      let jsonLayout =
        typeof val.templateJson === pageTypes.STRING
          ? JSON.parse(val.templateJson)
          : val.templateJson;
      val.templateJson = jsonLayout;
      if (jsonLayout && jsonLayout.redirectTo && jsonLayout.redirectTo !== "") {
        if (jsonLayout.openInNewTab) {
          window.open(jsonLayout.redirectTo, "_blank", "noopener,noreferrer");
        } else {
          window.location.href = jsonLayout.redirectTo;
        }
      } else {
        setLayout(null);
        history.push("/" + pageUrl + "/" + crmId + "/" + val.id);
        clearChartKeys();
        setTimeout(() => {
          setLayout(AddFilterToChartCard({ ...val }, props.currentPage));
        }, 100);
        setPageSubtitle(val.templateJson.title);
        setCurrentPageId(val.id);
      }
    }
  }

  const downloadExcelTemplatePopUp = (value) => {
    switch (value) {
      case pageTypes.EXCELTEMPLATE_DOWNLOAD:
        setIsExcelTemplateDownload(true);
        break;
      default:
        break;
    }
  };

  const handleClosePopup = (value) => {
    switch (value) {
      case pageTypes.EXCELTEMPLATE_DOWNLOAD:
        setIsExcelTemplateDownload(false);
        break;
      default:
        break;
    }
  };

  const [InitialSideBarClass, setInitialSideBarClass] =
    useState("page-sidebar");
  const toggleSideBarClass = () => {
    InitialBooleanValue
      ? setInitialSideBarClass("page-sidebar")
      : setInitialSideBarClass("page-sidebar2");
  };

  const [InitialBooleanValue, setInitialBooleanValue] = useState(false);
  const toggleBooleanValue = () => {
    InitialBooleanValue
      ? setInitialBooleanValue(false)
      : setInitialBooleanValue(true);
  };
  const reloadPage = () => {
    props.getCurrentPage({ pageUrl, crmId });
  };
  return (
    <PageContext.Provider
      value={{
        pageConfig: currentPage && newPageConfig,
        crmData: props.currentPage && props.currentPage.data,
        pageParameters: [],
      }}
    >
      {apiError && (
        <>
          <PageNotFound />
        </>
      )}
      {currentPage && !apiError ? (
        <main>
          <div
            className="openside-menu"
            onBlur={function () {
              toggleSideBarClass();
              toggleBooleanValue();
            }}
            tabIndex="0"
          >
            {!currentPage.isLoading && (
              <A_IconButton
                icon={
                  <ChevronRightIcon
                    className={
                      InitialBooleanValue
                        ? classes.ToggleButton
                        : classes.ToggleButtontwo
                    }
                  />
                }
                onClick={function () {
                  toggleSideBarClass();
                  toggleBooleanValue();
                }}
              ></A_IconButton>
            )}
          </div>
          <>
            {!currentPage.isLoading && (
              <article className="page-header">
                <M_TemplatePagesHeader
                  title={pageTitle}
                  subtitle={pageSubtitle}
                  downloadExcelTemplatePopUp={downloadExcelTemplatePopUp}
                  onReload={reloadPage}
                />
              </article>
            )}

            {currentPage &&
            currentPage.templates &&
            currentPage.templates.length > 0 ? (
              <article className={InitialSideBarClass} ref={layoutselection}>
                <O_LayoutMenu
                  data={viewOnlyLayouts}
                  currentLayoutTitle={
                    layout && layout.templateJson && layout.name
                  }
                  callbackParent={(val) => handleLayoutMenu(val)}
                  currentPageId={currentPageId}
                />
              </article>
            ) : (
              ""
            )}
            <article className="page-content">
              {!currentPage.isLoading ? (
                <Layout
                  manualRefresh={manualRefresh}
                  cards={
                    layout &&
                    layout.templateJson &&
                    handleShowHide(layout.templateJson.cards, crmDataForShow)
                  }
                  getCurrentPage={props.getCurrentPage}
                  data={props.currentPage.data}
                  handleClosePopup={handleClosePopup}
                  isExcelTemplateDownload={isExcelTemplateDownload}
                  params={{ pageUrl, crmId, templateId }}
                />
              ) : (
                <LoadingLayout />
              )}
            </article>
            <article className="page-toast">
              {toastStatus && (
                <A_Snackbar
                  open={toastStatus}
                  message={props.toast.message}
                  type={props.toast.severity}
                  autoHideDuration={2000}
                  vertical="bottom"
                  horizontal="center"
                  handleClose={handleSnackbarClose}
                />
              )}
            </article>
          </>
        </main>
      ) : !apiError ? (
        <>
          <LoadingMessage />
        </>
      ) : (
        ""
      )}
    </PageContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toast: state.toast,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      currentPageActions,
      manageExcelTemplateActions,
      addToolbarFilterActions,
      loggedInUserIdActions,
      toastActions
    ),
    dispatch
  );
}

P_PageLayout.defaultProps = {
  currentPage: {},
  loggedIn: {},
};
P_PageLayout.propTypes = {
  resetToolbarFilters: PropTypes.func,
  setTabs: PropTypes.func,
  currentPage: PropTypes.object,
  getCurrentPage: PropTypes.func,
  getLoggedInUser: PropTypes.func,
  loggedIn: PropTypes.object,
  toast: PropTypes.object,
  setToast: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(P_PageLayout);
